import { CheckCircle } from "../../assets/custom-icons";
import checkCircle from "../../assets/check-circle.svg";
import Payment from "../payment/Payment";
import { useEffect, useState } from "react";
import { ChakraProvider, Button } from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from "react-redux";
import SignUpUserPayment from "../payment/SignUpUserPayment";

const SignUpUserPaymentPlan = () => {
  const {userInfo} = useSelector((state)=>{
    return state.user
  })
  const userData = JSON.parse(localStorage.getItem("userData"))
  console.log("🚀 ~ SignUpUserPaymentPlan ~ userData:", userData)
  // console.log("🚀 ~ const{userInfo}=useSelector ~ userInfo:", userInfo)
  const stripePromise = loadStripe(
    "pk_test_51ORvl9JBlwRUWYLYchDzUJfzN7Wydmttw5QXKYZOgILBYiEZcZkNpuCN8wp8P7d9iR6iplFQK4jB8W7OzGy2hziX00QnM90CS3"
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setamount] = useState();
  const [stripeLoaded, setStripeLoaded] = useState(false);

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  useEffect(() => {
    const loadStripeElements = async () => {
      await stripePromise;
      setStripeLoaded(true);
    };

    loadStripeElements();
  }, []);

  // Define the plans array
  const plans = [
    {
      // name: "Starter",
      feature: "free plan",
      monthlyPrice: 5,
      priceCriteria:"per account",
      use: "Basic features for up to 1 account.",
      annualPrice: null, // Not provided in the code
      features: [
        "Single Account",
        "$5 Per Account",
        "Cold Email Blocker",
      ],
    },
    {
      // name: "Team",
      monthlyPrice: 30,
      priceCriteria:"per account",
      use: "Premium feature one-time 10+ sites data removal",
      annualPrice: null, // Not provided in the code
      feature: "Basic",
      features: [
        "Data removal",
        "Remove One Time Data Request",
        "10+ Sites",
      ],
    },
    // {
    //   name: "Enterprise",
    //   monthlyPrice: 40,
    //   use: "Advanced features +40 users.",
    //   feature: "Business",

    //   annualPrice: null, // Not provided in the code
    //   features: [
    //     "40+ Users",
    //     "Talk to sales",
    //     "Account manager",
    //     "20GB individual data each user",
    //     "Basic chat and email support",
    //   ],
    // },
  ];

  return (
    <>
      <div className="w-full max-h-[100vh] overflow-auto px-2 md:px-4 lg:px-8  py-12 bg-white flex-col justify-start items-center gap-8 inline-flex">
        <div className="self-stretch  px-8 flex-col justify-start items-center gap-8 flex">
          <div className="self-stretch  flex-col justify-start items-center gap-6 flex">
            <div className="self-stretch  h-24 flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch text-center text-yellow-600 text-base font-semibold  leading-normal">
                Pricing
              </div>
              <div className="self-stretch text-center text-gray-900 text-5xl font-semibold  leading-[60px]">
                Simple, transparent pricing
              </div>
            </div>
            <div className="self-stretch text-center text-slate-600 text-xl font-normal  leading-[30px]">
              We believe Lorem should be accessible to all companies, no matter
              the size.
            </div>
          </div>
          <div className="p-1.5 w-[fit-content] align-[center] bg-gray-50 rounded-xl border border-gray-200 justify-start items-center gap-1 inline-flex">
            {/* <div className=" px-3 py-2 bg-white rounded-md shadow justify-center items-center gap-2 flex">
              <div className="text-slate-700 text-base font-semibold  leading-normal min-w-[fit-content]">
                Monthly billing
              </div>
            </div> */}
            <div className=" px-3 py-2  rounded-md justify-center items-center gap-2 flex">
              <div className="text-gray-500 text-base font-semibold  leading-normal">
               Payment Plan
              </div>
            </div>
          </div>
          <div className="self-stretch  pb-24 flex-col lg:flex-row   flex gap-8 justify-center">
            {plans?.map((plan) => (
              <>
                <div
                  key={plan.feature}
                  className={`px-4 w-[400px] bg-white h-auto rounded-2xl shadow border ${
                    plan.name === "Team"
                      ? "border-yellow-500"
                      : "border-grey-500"
                  } flex-col justify-start items-start inline-flex`}
                >
                  <div className="self-stretch  p-8 border-b border-gray-200 flex-col justify-start items-start gap-8 flex">
                    <div className="self-stretch  flex-col justify-start items-start gap-4 flex">
                      <div className="self-stretch justify-start items-start gap-4 inline-flex">
                        <div className="grow shrink basis-0 text-slate-600 text-lg font-semibold  leading-7">
                          {plan.name}
                        </div>
                      </div>
                      <div className="self-stretch justify-start items-end gap-1 inline-flex">
                        <div className="text-gray-900 text-6xl font-semibold  leading-[72px]">
                          ${plan.monthlyPrice}
                        </div>
                        <div className="pb-2 justify-start items-start flex">
                          <div className="text-slate-600 text-base font-medium  leading-normal">
                            {plan.priceCriteria}
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch text-slate-600 text-base font-normal  leading-normal">
                        {plan.use}
                      </div>
                    </div>
                    <div className="self-stretch  flex-col justify-start items-start gap-3 flex">
                      <div onClick={()=>{
                        setamount(plan.monthlyPrice)
                        handleOpen()
                      }}  className="self-stretch cursor-pointer px-[18px] py-3 bg-yellow-500 rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 inline-flex">
                        <div className="px-0.5 justify-center items-center flex">
                          <div className="text-white text-base font-semibold  leading-normal">
                            Get started
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch px-8 pt-8 pb-10 flex-col justify-start items-start gap-6 flex">
                    <div className="self-stretch  flex-col justify-start items-start gap-1 flex">
                      <div className="self-stretch text-gray-900 text-base font-semibold  leading-normal">
                        FEATURES
                      </div>
                      {/* <div className="self-stretch">
                        <span className="text-slate-600 text-base font-normal  leading-normal">
                          Everything in{" "}
                        </span>
                        <span className="text-slate-600 text-base font-semibold  leading-normal">
                          {plan.feature}
                        </span>
                        <span className="text-slate-600 text-base font-normal  leading-normal">
                          {" "}
                          plus....
                        </span>
                      </div> */}
                    </div>
                    <div className="self-stretch  flex-col justify-start items-start gap-4 flex">
                      {plan.features?.map((txt, ind) => (
                        <div
                          className="self-stretch justify-start items-start  gap-3 inline-flex"
                          key={`${txt}-${ind}`}
                        >
                          <div className="w-6 h-6 rounded-xl justify-center items-center flex">
                            <img
                              src={checkCircle}
                              alt=""
                              className="w-6 h-6 relative flex-col justify-start items-start flex"
                            />
                          </div>
                          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                            <div className="self-stretch text-slate-600 text-[12px] xl:text-[16px] font-normal  leading-normal">
                              {txt}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      {/* <Stripepayment/> */}
      <Elements stripe={stripePromise}>
      <SignUpUserPayment isOpen={isModalOpen} onClose={handleClose} userData={userData} paymentAmount={amount} />
      </Elements>
    </>
  );
};
export default SignUpUserPaymentPlan;
