import React from "react";
import { ChakraProvider, Box, Heading, Text, Button, VStack } from "@chakra-ui/react";
import { useHistory, useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const nav = useNavigate();

  const handleBackToHome = () => {
    nav("/"); // Navigate to home or any other desired route
  };

  return (
    <ChakraProvider>
      <Box
        textAlign="center"
        py={10}
        px={6}
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bg="gray.50"
      >
        <VStack spacing={4} bg="white" p={8} borderRadius="lg" boxShadow="lg">
          <Heading as="h3" size="lg" mt={6} mb={2} color="black">
            Subscription Successful!
          </Heading>
          <Text mt={3} color="gray.500">
            Thank you for subscribing to our service. You have successfully subscribed.
          </Text>
          <Button
          mt={4}
            bg="#E7C801"
            _hover={{}}
            color={'white'}
            variant="solid"
            onClick={handleBackToHome}
          >
            Back to Home
          </Button>
        </VStack>
      </Box>
    </ChakraProvider>
  );
};

export default SuccessPage;
