import React from "react";

const TermsOfService = () => {
  return (
    <div className="py-8 px-20 font-sans">
      <h1 className="text-2xl font-bold mb-10">
        Terms of Service for Dylinn Email Classification
      </h1>

      <h2 className="text-xl font-semibold mb-2 mt-4">Introduction</h2>
      <p className="mb-4">
        Welcome to Dylinn Email Classification. By using our web application, you agree to these Terms of Service. Please read them
        carefully.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Using the App</h2>
      <p className="mb-4">
        You must follow any policies made available to you within the App. You
        agree to use the App only for lawful purposes and in accordance with
        these Terms of Service.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Account Requirements</h2>
      <p className="mb-4">
        To use the App, you must link your Gmail or Microsoft account. You are
        responsible for maintaining the confidentiality of your account
        information and for all activities that occur under your account.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Data Filtering</h2>
      <p className="mb-4">
        Our App filters marketing (cold) emails from your linked Gmail or
        Microsoft accounts. We strive to provide accurate filtering but do not
        guarantee that all marketing emails will be filtered out.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Data Removal Requests</h2>
      <p className="mb-4">
        The App provides functionality to generate data removal requests to
        remove your data from various sites. We facilitate these requests but
        cannot guarantee that all sites will comply.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Intellectual Property</h2>
      <p className="mb-4">
        The App and its original content, features, and functionality are and
        will remain the exclusive property of Dylinn Email Classification and
        its licensors.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Termination</h2>
      <p className="mb-4">
        We may terminate or suspend your access to the App immediately, without
        prior notice or liability, for any reason, including if you breach these
        Terms of Service.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Changes to These Terms</h2>
      <p className="mb-4">
        We may update our Terms of Service from time to time. We will notify you
        of any changes by posting the new Terms of Service on our website. You
        are advised to review these Terms periodically for any changes. Changes
        to these Terms are effective when they are posted on this page.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about these Terms, please contact us at
        support@dylinnemail.com.
      </p>

      <p className="mb-4">
        By using Dylinn Email Classification, you agree to the terms of these
        Terms of Service.
      </p>
    </div>
  );
};

export default TermsOfService;
