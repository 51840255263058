import React, { useState } from 'react'
import background from '../../../assets/background.svg'
import { Link, useNavigate } from 'react-router-dom';
import keyIcon from "../../../assets/key-icon.svg";
import emailIcon from '../../../assets/email-icon.svg';
import BackIcon from '../../../components/BackIcon';
import axios from "axios";
import { showNotification } from "../../../utils/error";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const base_url = process.env.REACT_APP_BASE_URL;
const ForgotPassword = () => {
    const validationSchema = Yup.object().shape({
    
        email: Yup.string()
          .matches(
            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
            "Invalid email address"
          )
          .required("Email is required"),
      });
    
      const initialValues = {
        email: "",
      }
    const navigate=useNavigate();

    const [isSend,setIsSend]=useState(false)
    const handleChange=()=>{
setIsSend(true)    
}
const [email,setEmail]=useState('')
const onSubmit = async (values, { setSubmitting }) => {
    try {
      const body = {
        email: values?.email || null,
      };
      const response = await axios.post(base_url + "/api/forgotPassword", body);
      if (response?.data?.status) {
        setEmail(values?.email||"")
        handleChange();
        // localStorage.setItem(
        //   "userData",
        //   JSON.stringify(response?.data?.body)
        // );
        // localStorage.setItem("jwt", response?.data?.body?.token);
        // login(response?.data?.body?.token);
        // navigate("/home");
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("success", response?.data?.message);
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("error", response?.data?.message);
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitting(false);
      }, 2000);
      showNotification("error", error?.response?.data?.message || error?.message);
    }
  };
  const [isSubmitting,setSubmitting]=useState(false)
  const resendEmail = async () => {
    try {
      const body = {
        email: email || null,
      };
      const response = await axios.post(base_url + "/api/forgotPassword", body);
      if (response?.data?.status) {
        setEmail(email||"")
        handleChange();
        // localStorage.setItem(
        //   "userData",
        //   JSON.stringify(response?.data?.body)
        // );
        // localStorage.setItem("jwt", response?.data?.body?.token);
        // login(response?.data?.body?.token);
        // navigate("/home");
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("success", response?.data?.message);
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("error", response?.data?.message);
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitting(false);
      }, 2000);
      showNotification("error", error?.response?.data?.message || error?.message);
    }
  };
  return (
    <div className="w-full h-[100vh] px-8   bg-white flex-col justify-center items-center  flex"  >
   
    <div className="w-[33%] h-[100vh]  px-8 flex-col justify-center items-center gap-8 flex" style={{background:`url(${background})`,}}>
        <div className="self-stretch flex-col justify-start items-center gap-8 flex">
            {
                isSend?<>
                 <div className="self-stretch h-[154px] flex-col justify-start items-center gap-6 flex">
                <img src={emailIcon} alt="ok" />
                <div className="self-stretch h-[98px] flex-col justify-start items-start gap-3 flex">
                    <div className="self-stretch text-center text-gray-900 text-3xl font-semibold  leading-[38px]">Check your email</div>
                    <div className="self-stretch text-center"><span className="text-slate-600 text-base font-normal  leading-normal">We sent a password reset link to </span><span className="text-slate-600 text-base font-medium  leading-normal">{email||''}</span></div>
                </div>
            </div>
            <a href={'https://mail.google.com/mail'} target='_blank' referrerPolicy='no-referrer'  className="self-stretch px-4 py-2.5 bg-yellow-500 rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 inline-flex">
                <div className="px-0.5 justify-center items-center flex">
                    <div className="text-white text-base font-semibold  leading-normal">Open email app</div>
                </div>
            </a>
            <div className="self-stretch justify-center items-start gap-1 inline-flex">
                <div className="text-slate-600 text-sm font-normal  leading-tight">Didn’t receive the email?</div>
                <div className="justify-center items-center gap-1.5 flex">
                    <button disabled={isSubmitting} onClick={resendEmail} className="text-yellow-600 text-sm font-semibold  leading-tight">Click to resend</button>
                </div>
            </div>
            <Link to='/' className="justify-center items-center gap-1.5 inline-flex">
            <BackIcon/>
                <div  className="text-slate-600 text-sm font-semibold  leading-tight">Back to log in</div>
            </Link>
                </>:
                <>
            <div className="self-stretch h-[154px] flex-col justify-start items-center gap-6 flex">
                <img src={keyIcon} alt="ok" />
                
                <div className="self-stretch h-[74px] flex-col justify-start items-start gap-3 flex">
                    <div className="self-stretch text-center text-gray-900 text-3xl font-semibold  leading-[38px]">Forgot password?</div>
                    <div className="self-stretch text-center text-slate-600 text-base font-normal  leading-normal">No worries, we’ll send you reset instructions.</div>
                </div>
            </div>
            <div className='w-full'>

            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit} >
            {({ isSubmitting }) => (
              <Form>
                  <div className="pb-4">
                <div className="text-slate-700 text-sm font-medium  leading-tight py-2">Email *</div>
                    <div className=" w-full    px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300  items-center flex">
                        <Field
                          type="text"
                          name="email"
                          placeholder="Enter your email"
                          className="w-full text-[#667085] text-base font-normal  leading-normal bg-white border-none outline-none"
                        />
                    </div>
                    <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-600 text-[14px] "
                  />                </div>
                 <button
                  disabled={isSubmitting}
                  type='submit'
                  className={`w-full py-2.5  bg-[#E7C801] rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 inline-flex`}>
                  <div className="justify-center items-center flex">
                    <div className="text-white text-base font-semibold  leading-normal">
                    {isSubmitting ? (
                       <div className="">
                       <ButtonLoader />
                     </div>
                    ) : (
                      "Reset password"
                    )}
                    </div>
                  </div>
                </button>
                </Form>
            )}
          </Formik>
            </div>
            <Link to='/'  className="justify-center items-center gap-1.5 inline-flex">
                <BackIcon/>
                <div  className="text-slate-600 text-sm font-semibold  leading-tight">Back to log in</div>
            </Link>
                </>
            }
        </div>
    </div>
</div>
  )
}

export default ForgotPassword