import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="py-8 px-20 font-sans">
      <h1 className="text-2xl font-bold mb-10">Privacy Policy for Dylinn Email Classification</h1>

      <h2 className="text-xl font-semibold mb-2 mt-4">Introduction</h2>
      <p className="mb-4">
        Welcome to Dylinn Email Classification. We are committed to protecting
        your privacy. This Privacy Policy explains how we collect, use, and
        safeguard your personal information when you use our web application,
        Dylinn Email Classification.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Information We Collect</h2>
      {/* <h3 className="text-lg font-medium mt-3">Personal Information</h3> */}
      <p className="mb-4">
        <strong>Email Addresses:</strong> We collect email addresses when you
        link your Gmail or Microsoft accounts to the App.
        <br />
        <strong>Account Information:</strong> We collect basic information from
        your Gmail and Microsoft accounts to enable our email filtering
        services.
      </p>

      <h3 className="text-lg font-medium mt-3">Usage Data</h3>
      <p className="mb-4">
        We collect information on how you interact with the App, such as the
        features you use and the frequency of your usage.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">How We Use Your Information</h2>
      {/* <h3 className="text-lg font-medium mt-3">To Provide Services</h3> */}
      <p className="mb-4">
        <strong>Email Filtering:</strong> We use your linked Gmail and Microsoft
        account information to filter out marketing (cold) emails and enhance
        your email experience.
        <br />
        <strong>Data Removal Requests:</strong> We facilitate data removal
        requests to help you remove your data from various sites.
      </p>

      <h3 className="text-lg font-medium mt-3">To Improve the App</h3>
      <p className="mb-4">
        We analyze usage data to improve our features, enhance user experience,
        and ensure the App runs smoothly.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Data Sharing and Disclosure</h2>
      <h3 className="text-lg font-medium mt-3">Third-Party Services</h3>
      <p className="mb-4">
        We do not share your personal information with third parties except to
        provide the services described above, comply with legal obligations, or
        with your consent.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Data Security</h2>
      <p className="mb-4">
        We implement appropriate technical and organizational measures to
        protect your personal information from unauthorized access, use, or
        disclosure.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Your Rights</h2>
      <h3 className="text-lg font-medium mt-3">Access and Control</h3>
      <p className="mb-4">
        You have the right to access, correct, or delete your personal
        information. You can also unlink your Gmail or Microsoft accounts at any
        time through the App settings.
      </p>

      <h3 className="text-lg font-medium mt-3">Data Removal Requests</h3>
      <p className="mb-4">
        You can generate data removal requests through the App to remove your
        data from various sites. We will facilitate these requests but cannot
        guarantee that all sites will comply.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Changes to This Privacy Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on our website.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-4">Contact Us</h2>
      <p className="mb-2">
        If you have any questions about this Privacy Policy or our data
        practices, please contact us at support@dylinnemail.com.
      </p>

      <p className="mb-4">
        By using Dylinn Email Classification, you agree to the terms of this
        Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
