import React, { useEffect, useState } from "react";
import MainHeaderSideBar from "../../components/mainLayout/MainHeaderSideBar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  ChakraProvider,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  chakra,
} from "@chakra-ui/react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import ButtonLoader from "../../components/loaders/ButtonLoader";
import Loader from "../../components/loaders/Loader";
import { showNotification } from "../../utils/error";
import axiosInstance from "../../utils/axiosInstance";
import axios from "axios";
import { useSelector } from "react-redux";
import ConnectModal from "../../components/modals/ConnectModal";

const Analytics = () => {
  const { userInfo } = useSelector((state) => {
    return state.user;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [analyticsData, setAnalyticsData] = useState();
  const [initialValues, setInitialValues] = useState({
    price: "",
  });

  const validationSchema = Yup.object().shape({
    price: Yup.string().required("Rate is required"),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    const token = localStorage.getItem("jwt");

    try {
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const userData = JSON.parse(localStorage.getItem("userData"));
      const body = {
        type: userInfo?.selectedTab,
        per_hour_rate: values?.price || null,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/set-rate`,
        body,
        {
          headers: myHeaders,
        }
      );
      if (response?.data?.status) {
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("success", response?.data?.message);
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("error", response?.data?.message);
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitting(false);
      }, 2000);
      showNotification(
        "error",
        error?.response?.data?.message || error?.message
      );
    }
  };

  const fetchRate = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("jwt");
    try {
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const userData = JSON.parse(localStorage.getItem("userData"));
      const body = {
        type: userInfo?.selectedTab,
      };
      const response = await axiosInstance.post("/api/get-rate", body, {
        headers: myHeaders,
      });
      if (response?.status) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setInitialValues({ price: response?.data?.data?.per_hour_rate || "" });
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      showNotification("error", error?.response?.data?.error || error?.message);
    }
  };

  const fetchAnalytics = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("jwt");
    try {
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const userData = JSON.parse(localStorage.getItem("userData"));
      const body = {
        type: userInfo?.selectedTab,
      };
      const response = await axiosInstance.post("/api/analytics-count", body, {
        headers: myHeaders,
      });
      if (response?.status) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setAnalyticsData(response?.data);
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      showNotification("error", error?.response?.data?.error || error?.message);
    }
  };

  useEffect(() => {
    if (userInfo?.selectedTab) {
      if (
        !Array.isArray(userInfo.social_login) ||
        !userInfo.social_login.some((obj) => obj.type === userInfo.selectedTab)
      ) {
        setIsOpen(true);
        return;
      }
    }
    fetchAnalytics();
    fetchRate();
  }, [userInfo]);

  return (
    <MainHeaderSideBar headingText={"Analytics"}>
      <ConnectModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        microsoft={userInfo.selectedTab == "microsoft"}
        google={userInfo.selectedTab == "google"}
      />
      <ChakraProvider>
        <div className="w-full overflow-auto h-[80vh] pt-6 pb-16 ">
          {isLoading ? (
            <div className="flex justify-center items-center h-[40vh]">
              <Loader />
            </div>
          ) : (
            <>
              <Flex w="100%" px={4} gap={"20px"} mb={4} flexWrap={"wrap"}>
                <Stack
                  width="250px"
                  borderRadius={"12px"}
                  border={"1px solid #EAECF0"}
                  py={4}
                  px={8}
                  boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
                >
                  <div className="self-stretch text-gray-900 text-lg font-semibold  leading-[38px] text-center">
                    Total reviewed emails
                  </div>
                  <div className="self-stretch text-gray-900 text-3xl font-bold mt-2 text-center leading-[38px]">
                    {analyticsData?.total_viewed}
                  </div>
                </Stack>
                <Stack
                  width="230px"
                  borderRadius={"12px"}
                  border={"1px solid #EAECF0"}
                  py={4}
                  px={8}
                  boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
                >
                  <div className="self-stretch text-gray-900 text-lg font-semibold text-center  leading-[38px]">
                    Paid cold emails
                  </div>
                  <div className="self-stretch text-gray-900 text-3xl font-bold mt-2 text-center leading-[38px]">
                    {analyticsData?.paid_count}
                  </div>
                </Stack>
                <Stack
                  width="230px"
                  borderRadius={"12px"}
                  border={"1px solid #EAECF0"}
                  py={4}
                  px={8}
                  boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
                >
                  <div className="self-stretch text-gray-900 text-lg font-semibold text-center  leading-[38px]">
                    Unpaid cold emails
                  </div>
                  <div className="self-stretch text-gray-900 text-3xl font-bold mt-2 text-center leading-[38px]">
                    {analyticsData?.unpaid_count}
                  </div>
                </Stack>
                <Stack
                  width="230px"
                  borderRadius={"12px"}
                  border={"1px solid #EAECF0"}
                  py={4}
                  px={8}
                  boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
                >
                  <div className="self-stretch text-gray-900 text-lg font-semibold text-center  leading-[38px]">
                    Paid meetings
                  </div>
                  <div className="self-stretch text-gray-900 text-3xl font-bold mt-2 text-center leading-[38px]">
                    {analyticsData?.meeting_count}
                  </div>
                </Stack>
              </Flex>
              <div className="self-stretch px-4 pt-5 text-gray-900 text-2xl font-semibold leading-[38px]">
                Set Hourly Meeting Rates
              </div>
              <Stack
                mt={4}
                mx={4}
                borderRadius={"12px"}
                border={"1px solid #EAECF0"}
                py={4}
                px={8}
                boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
              >
                <div className="w-full">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="w-full">
                          <div className="pb-4">
                            <div className="text-slate-700 text-md font-medium leading-tight py-2">
                              Per hour rate *
                            </div>
                            <div className="w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 items-center flex">
                              <Field
                                type="number"
                                name="price"
                                placeholder="Enter the per hour rate here"
                                className="grow shrink basis-0 text-[#667085] text-base font-normal leading-normal bg-white border-none outline-none"
                              />
                            </div>
                            <ErrorMessage
                              name="price"
                              component="div"
                              className="text-red-600 text-[14px] mt-1"
                            />
                          </div>
                          <div className="flex justify-end mt-5">
                            <button
                              disabled={isSubmitting}
                              className={`w-[fit-content] align--self-end py-2.5 mt-3 px-4 bg-[#E7C801] rounded-md shadow border border-yellow-500 justify-center items-center gap-1.5 flex`}
                            >
                              <div className="text-white text-base font-semibold leading-normal">
                                {isSubmitting ? (
                                  <div className="">
                                    <ButtonLoader />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </div>
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Stack>
            </>
          )}
        </div>
      </ChakraProvider>
    </MainHeaderSideBar>
  );
};

export default Analytics;
