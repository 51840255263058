import moment from "moment";

const routeTitles = {
  '/':"EZ PZ | Login Page",
  '/inbox':'Inbox | Page',
  '/payment-success': 'Payment Success',
  '/connect':'Connect Page',
  '/calender':'Calenders Page',
  '/paid-mails':'Paid Mails',
  '/unpaid-emails':'Unpaid Mails'

};
const dateSimplewithComma = (incomingDate) => {
  return moment(incomingDate).format('hh:mm A');
};
const dateWithMonth= (incomingDate) => {
  return moment(incomingDate).format('MMM D');
};
const dateAndTime = (incomingDate) => {
  return moment(incomingDate).format('MMM D, YYYY hh:mm A');
};
const events=[
  {
      title: "July 1 Bank Holiday",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-07-01",
      end: "2024-07-02",
      link: "https://www.google.com/calendar/event?eid=MjAyNDA3MDFfMmpia2hncDdoZHBycm5mNzhjaGVsNWRicXMgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Ashura",
      description: "Public holiday",
      start: "2024-07-16",
      end: "2024-07-17",
      link: "https://www.google.com/calendar/event?eid=MjAyNDA3MTZfbW5mbTNrbWdqZ2VnMmlhZ3RtNGE4cnY1a2cgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Ashura Holiday",
      description: "Public holiday",
      start: "2024-07-17",
      end: "2024-07-18",
      link: "https://www.google.com/calendar/event?eid=MjAyNDA3MTdfb2VhZzRjc2hha2MzM3RsczMyNTl1MDVsZjQgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Independence Day",
      description: "Public holiday",
      start: "2024-08-14",
      end: "2024-08-15",
      link: "https://www.google.com/calendar/event?eid=MjAyNDA4MTRfamFyNmc1am1kZXBubDRkNWc0djQ3aDh0OGMgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Birthday of Lord Zoroaster (Khordad Sal)",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-08-20",
      end: "2024-08-21",
      link: "https://www.google.com/calendar/event?eid=MjAyNDA4MjBfMDRlaXYyMW9vNWhmNjJwZTIxcG5lOWtmdmsgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Janmashtami",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-08-26",
      end: "2024-08-27",
      link: "https://www.google.com/calendar/event?eid=MjAyNDA4MjZfanRxb2thdDZrYWxmMW04NGM1Nzlqa3Y3NmsgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Chelum",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-08-27",
      end: "2024-08-28",
      link: "https://www.google.com/calendar/event?eid=MjAyNDA4MjdfaDRlaGsxbW8xM3U4MHU3bWtycHQydTE1Nm8gZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Defence Day",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-09-06",
      end: "2024-09-07",
      link: "https://www.google.com/calendar/event?eid=MjAyNDA5MDZfb291ZmVuNWJxbDI5cGtzOWkxbnQ4aGw0cDQgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Eid Milad un-Nabi",
      description: "Public holiday",
      start: "2024-09-16",
      end: "2024-09-17",
      link: "https://www.google.com/calendar/event?eid=MjAyNDA5MTZfYnBwZHNxNTNraGVzcDFwanE1cWRrMjQ4a2cgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Durga Puja",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-10-11",
      end: "2024-10-12",
      link: "https://www.google.com/calendar/event?eid=MjAyNDEwMTFfbjkyM3E5YzZ2OXZmZTFvMWhicXJubHVndTAgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Dussehra",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-10-12",
      end: "2024-10-13",
      link: "https://www.google.com/calendar/event?eid=MjAyNDEwMTJfZmowcDZzZTk5dnYybWFpNDZiM3E5dGx1c2cgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Giarhwin Sharief",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-10-16",
      end: "2024-10-17",
      link: "https://www.google.com/calendar/event?eid=MjAyNDEwMTZfMm92dGVqdmpnaGs4OXNpa2R2ODlxYzAxdWcgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Birthday of Guru Balmik Sawami Ji",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-10-17",
      end: "2024-10-18",
      link: "https://www.google.com/calendar/event?eid=MjAyNDEwMTdfajlwZGJxN2N0ajZub3JoMHE4MDVubTcyc2MgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Diwali/Deepavali",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-11-01",
      end: "2024-11-02",
      link: "https://www.google.com/calendar/event?eid=MjAyNDExMDFfdnVwYW5uNnA4dmxna2JsOGQ5NjN0dmFrYWMgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Iqbal Day",
      description: "Public holiday",
      start: "2024-11-09",
      end: "2024-11-10",
      link: "https://www.google.com/calendar/event?eid=MjAyNDExMDlfZDQ3c29obnIzbGo3dTFlaGdhZmhvbjR1djggZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Guru Nanak's Birthday",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-11-15",
      end: "2024-11-16",
      link: "https://www.google.com/calendar/event?eid=MjAyNDExMTVfaTk1cjBtbnQxbnJiZWVrb2tyNW1iazJydmcgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Christmas Eve",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-12-24",
      end: "2024-12-25",
      link: "https://www.google.com/calendar/event?eid=MjAyNDEyMjRfaHRzZDNxajVzcW9zNTJkdGc5djhqMDgwdTggZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Quaid-e-Azam Day",
      description: "Public holiday",
      start: "2024-12-25",
      end: "2024-12-26",
      link: "https://www.google.com/calendar/event?eid=MjAyNDEyMjVfbG5tNGU5YjMycXI0cmZwNjBncG1tYnVtbTcgZW4ucGsjaG9saWRheUB2"
  },
  {
      title: "Day after Christmas",
      description: "Observance\nTo hide observances, go to Google Calendar Settings > Holidays in Pakistan",
      start: "2024-12-26",
      end: "2024-12-27",
      link: "https://www.google.com/calendar/event?eid=MjAyNDEyMjZfZjM4NzExaGZnZmI2OW9sMGgyN2Z2MG1qYmsgZW4ucGsjaG9saWRheUB2"
  }
]
export { routeTitles,dateSimplewithComma,dateWithMonth,dateAndTime,events };
