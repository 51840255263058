import { Navigate } from "react-router-dom";

function ProtectedRoute({ children }) {
  const jwt = localStorage.getItem("jwt");

  if (!jwt) {
    return <Navigate to={"/"} />;
  }

  return children;
}

export default ProtectedRoute;
