import React, { useState } from "react";
import background from "../../../assets/background.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import lockIcon from "../../../assets/lock-icon.svg";
import checkCircle from "../../../assets/checkCircle.svg";
import BackIcon from "../../../components/BackIcon";

import { showNotification } from "../../../utils/error";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { EyeIcon, UnHideEyeIcon } from "../../../assets/custom-icons";

const base_url = process.env.REACT_APP_BASE_URL;

const NewPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSet,setIsSet]=useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleCofirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const navigate = useNavigate();
  const { id } = useParams();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/~\\-])[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?/~\\-]*$/,
        "At least one special character and no spaces."
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm New Password is required"),
  });

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const body = {
        password: values?.password || null,
        email: id || null,
      };
      const response = await axios.post(base_url + "/api/newpassword", body);
      if (response?.data?.status) {
        setIsSet(true)
        // localStorage.setItem("userData", JSON.stringify(response?.data?.data));
        // localStorage.setItem("jwt", response?.data?.token);
        // login(response?.data?.body?.token);
        navigate("/");
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("success", response?.data?.message);
      } else {
        setTimeout(() => {
            setIsSet(false)
          setSubmitting(false);
        }, 2000);
        showNotification("error", response?.data?.message);
      }
    } catch (error) {
      setTimeout(() => {
        setIsSet(false)
        setSubmitting(false);
      }, 2000);
      showNotification(
        "error",
        error?.response?.data?.message || error?.message
      );
    }
  };
  return (
    <div className="w-full h-[100vh] px-8   bg-white flex-col justify-center items-center  flex">
      <div
        className="w-[33%] h-[100vh]  px-8 flex-col justify-center items-center gap-4 flex"
        style={{ background: `url(${background})` }}
      >
        {isSet ? (
          <>
            <div className="w-full  flex-col justify-start items-center gap-8 inline-flex">
              <div className="  flex-col  items-center gap-6 flex">
                <img src={checkCircle} alt="ok" />
                <div className=" ">
                  <div className=" text-center text-gray-900 text-3xl font-semibold  leading-[38px]">
                    Password reset
                  </div>
                  <div className=" text-center text-slate-600 text-base font-normal  leading-normal">
                    Your password has been successfully reset. Click below to
                    log in magically.
                  </div>
                </div>
              </div>
              {/* <button className="w-full px-4 py-2.5 bg-[#E7C801] rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 inline-flex">
                <div className="px-0.5 justify-center items-center flex">
                  <div className="text-white text-base font-semibold  leading-normal">
                    Continue
                  </div>
                </div>
              </button> */}
            </div>
          </>
        ) : (
          <>
            <div className="w-full  flex-col items-center gap-6 flex">
              <img src={lockIcon} alt="ok" />
              <div className="  flex-col  gap-3 flex">
                <div className=" text-center text-gray-900 text-3xl font-semibold  leading-[38px]">
                  Set new password
                </div>
                <div className=" text-center text-slate-600 text-base font-normal  leading-normal">
                  Your new password must be different to previously used
                  passwords.
                </div>
              </div>
            </div>
            <div className="w-full">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="pb-4">
                      <div className="text-slate-700 text-sm font-medium  leading-tight py-2">
                        Password *
                      </div>
                      <div className=" w-full    px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300  items-center flex">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Enter your password"
                          className="w-full text-[#667085] text-base font-normal  leading-normal bg-white border-none outline-none"
                        />
                        <span className="pl-2">
                          {showPassword ? (
                            <EyeIcon
                              onClick={togglePasswordVisibility}
                              className="cursor-pointer"
                            />
                          ) : (
                            <UnHideEyeIcon
                              onClick={togglePasswordVisibility}
                              className="cursor-pointer"
                            />
                          )}
                        </span>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-600 text-[14px] "
                      />
                    </div>
                    <div className="pb-4">
                      <div className="text-slate-700 text-sm font-medium  leading-tight py-2">
                        Confirm password *
                      </div>
                      <div className=" w-full    px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300  items-center flex">
                        <Field
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          placeholder="Confirm your password"
                          className="w-full text-[#667085] text-base font-normal  leading-normal bg-white border-none outline-none"
                        />
                        <span className="pl-2">
                          {showConfirmPassword ? (
                            <EyeIcon
                              onClick={toggleCofirmPasswordVisibility}
                              className="cursor-pointer"
                            />
                          ) : (
                            <UnHideEyeIcon
                              onClick={toggleCofirmPasswordVisibility}
                              className="cursor-pointer"
                            />
                          )}
                        </span>
                      </div>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-red-600 text-[14px] "
                      />{" "}
                    </div>

                    <div className="  flex-col  gap-3 py-2 flex">
                      <div className="  gap-2 flex">
                        <div className="w-5 h-5 relative bg-gray-300 rounded-[10px]" />
                        <div className="grow shrink basis-0 text-slate-600 text-sm font-normal  leading-tight">
                          Must be at least 8 characters
                        </div>
                      </div>
                      <div className="  gap-2 flex">
                        <div className="w-5 h-5 relative bg-gray-300 rounded-[10px]" />
                        <div className="grow shrink basis-0 text-slate-600 text-sm font-normal  leading-tight">
                          Must contain one special character
                        </div>
                      </div>
                    </div>

                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className={`w-full py-2.5  bg-[#E7C801] rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 inline-flex`}
                    >
                      <div className="justify-center items-center flex">
                        <div className="text-white text-base font-semibold  leading-normal">
                          {isSubmitting ? (
                            <div className="">
                              <ButtonLoader />
                            </div>
                          ) : (
                            "Reset password"
                          )}
                        </div>
                      </div>
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}

        <Link
          to={"/"}
          className="justify-center items-center gap-1.5 inline-flex"
        >
          <BackIcon />
          <div className="text-slate-600 text-sm font-semibold  leading-tight">
            Back to log in
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NewPassword;
