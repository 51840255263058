import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import profileLogo from "../../assets/profile_mask2.png";
import MainHeaderSideBar from "../../components/mainLayout/MainHeaderSideBar";
import Loader from "../../components/loaders/Loader";
import { dateAndTime, dateWithMonth } from "../../utils/constants";
import axiosInstance from "../../utils/axiosInstance";
import { showNotification } from "../../utils/error";
import { FaFile, FaArrowDown } from "react-icons/fa";
import { useSelector } from "react-redux";

// Utility function to decode base64 to Blob
const decodeBase64ToBlob = (base64String, mimeType) => {
  try {
    const byteCharacters = atob(
      base64String.replace(/-/g, "+").replace(/_/g, "/")
    );
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  } catch (error) {
    console.error("Error decoding base64 string:", error);
    return null;
  }
};

// Utility function to create download link
const createDownloadLink = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
};

const InboxId = () => {
  const { userInfo } = useSelector((state) => {
    return state.user;
  });
  const { id } = useParams();
  const location = useLocation();
  const [mailId, setMailId] = useState(id);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [allMessages, setAllMessages] = useState(location?.state?.allMessages);
  const [attachments, setAttachments] = useState([]);
  console.log("🚀 ~ InboxId ~ attachments:", attachments);
  
  const messageRefs = useRef({});

  const getMessage = async (idMail) => {
    setIsLoading(true);
    try {
      const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : {};
      const body = {
        token: userData?.access_token,
        mail_id: idMail,
        is_read: location?.state?.from === "inbox" ? 0 : 1,
      };
      const path =
        userInfo?.selectedTab === "microsoft"
          ? "/fetch-outlook-mail-id"
          : "/fetch-emails-id";

      const response = await axiosInstance.post(`/api${path}`, body);
      if (response?.data?.data || response?.data) {
        const fetchedMessage = response?.data?.data || response?.data;
        setMessage(fetchedMessage);

        let processedAttachments = [];
        if (
          userInfo?.selectedTab === "google" &&
          fetchedMessage.attachments
        ) {
          const attachmentData = JSON.parse(fetchedMessage.attachments);
          processedAttachments = attachmentData
            .map(({ data, mimeType, filename }) => {
              const blob = decodeBase64ToBlob(data, mimeType);
              if (blob) {
                const url = URL.createObjectURL(blob);
                return { url, mimeType, filename, blob };
              } else {
                return null;
              }
            })
            .filter((item) => item !== null);
        } else if (
          userInfo?.selectedTab === "microsoft" &&
          fetchedMessage.attachments
        ) {
          const attachmentData = JSON.parse(fetchedMessage.attachments);
          processedAttachments = attachmentData?.value
            .map(({ contentBytes, contentType, name }) => {
              const blob = decodeBase64ToBlob(contentBytes, contentType);
              if (blob) {
                const url = URL.createObjectURL(blob);
                return { url, mimeType: contentType, filename: name, blob };
              } else {
                return null;
              }
            })
            .filter((item) => item !== null);
        }

        setAttachments(processedAttachments);

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      showNotification(
        "error",
        error?.response?.data?.message || error.message
      );
    }
  };

  const getAllMessages = async () => {
    try {
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const userData = JSON.parse(localStorage.getItem("userData"));
      const body = {
        token: userData?.access_token,
        ...(location?.state.from !== "inbox" && {
          type: userInfo?.selectedTab,
        }),
      };

      const path = location?.pathname?.includes("/paid-mails")
        ? "/fetch-paid-emails"
        : location?.pathname?.includes("/unpaid-emails")
        ? "/fetch-unpaid-emails"
        : userInfo?.selectedTab === "microsoft"
        ? "/fetch-outlook-mails"
        : "/fetch-emails";
      await axiosInstance
        .post(`/api${path}`, body, {
          headers: myHeaders,
        })
        .then((res) => {
          if (res) {
            if (res?.data?.status) {
              setAllMessages(res?.data?.emails);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      showNotification("error", error?.response?.data?.error || error?.message);
    }
  };

  useEffect(() => {
    getMessage(id);
    getAllMessages();
  }, [id]);

  useEffect(() => {
    if (messageRefs.current[mailId]) {
      setTimeout(() => {
        messageRefs?.current[mailId]?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 300); // Add a slight delay to ensure the elements have fully rendered
    }
  }, [mailId, allMessages]);

  const getContent = () => {
    const htmlContent = message.body.find(
      (content) => content.type === "text/html"
    );
    const plainTextContent = message.body.find(
      (content) => content.type === "text/plain"
    );
    const anyContent = message.body.find((content) => content.type === "any");

    const isHTML = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

    if (htmlContent) {
      return <div dangerouslySetInnerHTML={{ __html: htmlContent.content }} />;
    }

    if (plainTextContent) {
      return <div>{plainTextContent.content}</div>;
    }

    if (anyContent) {
      if (isHTML(anyContent.content)) {
        return <div dangerouslySetInnerHTML={{ __html: anyContent.content }} />;
      } else {
        return <div>{anyContent.content}</div>;
      }
    }

    return null;
  };

  useEffect(() => {
    const title = "Inbox Details";
    document.title = title;
  }, []);

  return (
    <MainHeaderSideBar
      headingText={
        location?.pathname?.includes("/paid-mails")
          ? "Paid block mails"
          : location?.pathname?.includes("/unpaid-emails")
          ? "Unpaid block mails"
          : "Inbox"
      }
    >
      <div className="w-full flex-col justify-start items-start flex">
        <div className="w-full grow border-t shrink basis-0 self-stretch justify-start items-start gap-px flex">
          <div className="w-[38%] border-b h-[75vh] overflow-auto pt-6 border-r border-gray-200 flex-col gap-6 inline-flex">
            <div className="flex-col flex">
              {allMessages?.map((message) => (
                <Link
                  to={`/${
                    location.pathname.includes("/unpaid-emails")
                      ? "unpaid-emails"
                      : location.pathname.includes("/paid-mails")
                      ? "/paid-mails"
                      : "inbox"
                  }/${message?.mail_id}`}
                  key={message?.mail_id}
                  onClick={() => {
                    if (message?.mail_id === id) {
                      return;
                    }
                    getMessage(message?.mail_id);
                    setMailId(message?.mail_id);
                  }}
                  state={{ allMessages: location?.state?.allMessages }}
                  className={`${
                    message?.mail_id === id ? "bg-gray-100 pl-4" : " pl-4"
                  }`}
                >
                  <div
                    ref={(el) => (messageRefs.current[message?.mail_id] = el)}
                    id={`${message?.mail_id}`}
                    className={`w-full pr-6 py-4 h-[130px] border-b border-gray-200 gap-4 flex justify-between flex-row`}
                  >
                    <div className="flex w-[80%] gap-4">
                      <img
                        alt=""
                        className="w-[49px] h-[49px] rounded-full"
                        src={profileLogo}
                      />
                      <div className="w-full relative flex flex=col gap-2">
                        <div className="w-[307px] h-[84px] left-0 top-0 absolute">
                          <div className="left-0 top-0 absolute text-black text-lg font-medium">
                            {message?.from_name?.length > 25
                              ? `${message?.from_name.slice(0, 25)}...`
                              : message?.from_name}
                          </div>
                          <div className="w-[307px] h-[22px] left-0 top-[25px] absolute justify-between items-center inline-flex">
                            <div className="text-gray-800 text-sm font-normal">
                              {message?.subject?.length > 25
                                ? `${message?.subject
                                    .slice(0, 25)
                                    ?.replace(/\s+/g, " ")}...`
                                : message?.subject?.replace(/\s+/g, " ")}
                            </div>
                          </div>
                          <div className="w-[307px] left-0 top-[54px] absolute text-slate-600 text-xs font-normal">
                            {message?.snippet?.length > 100
                              ? `${message?.snippet.slice(0, 100)}...`
                              : message?.snippet}
                          </div>
                        </div>
                        <div className="w-[105px] h-[27px] left-0 top-[94px] absolute justify-start items-start gap-2 inline-flex"></div>
                      </div>
                    </div>

                    <div className="text-gray-400 text-xs font-normal">
                      {message?.datetime
                        ? dateWithMonth(message?.datetime)
                        : ""}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="w-[62%] h-[75vh] border-b border-gray-200 overflow-auto pl-4 py-6 flex-col justify-center items-center pr-4">
            {isLoading ? (
              <div className="flex justify-center items-center h-[40vh]">
                <Loader />
              </div>
            ) : (
              <>
                <div
                  className="w-full flex-col justify-start items-center gap-8 flex"
                  id="message"
                >
                  <div className="flex-col w-full gap-6 flex">
                    <div className="w-full flex justify-between">
                      <div className="flex flex-row items-center gap-4">
                        <img
                          alt=""
                          className="w-[64.89px] h-[60px] rounded-full"
                          src={profileLogo}
                        />
                        <div className="text-black text-lg font-medium">
                          {message?.from_name}
                        </div>
                      </div>
                      <div className="text-zinc-500 text-sm font-normal">
                        {message?.datetime
                          ? dateAndTime(message?.datetime)
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch h-[645.15px] flex-col justify-start items-start gap-6 flex">
                    <div className="text-black text-[24px] font-medium leading-[38px]">
                      {message?.subject}
                    </div>
                    {attachments?.length > 0 && (
                      <div className="w-full border-t pt-2">
                        <div className="text-gray-500 text-sm font-medium mb-2">
                          {attachments?.length} attachment
                          {attachments?.length > 1 ? "s" : ""}
                        </div>
                        <div className="flex flex-wrap gap-4">
                          {attachments?.map((attachment, index) => (
                            <div
                              key={index}
                              className="relative border p-2 rounded-lg flex justify-center items-center h-[150px] w-[180px]"
                            >
                              {attachment?.mimeType.startsWith("image/") && (
                                <img
                                  src={attachment?.url}
                                  alt={attachment?.filename}
                                  className="attachment-preview w-full h-auto"
                                />
                              )}
                              {attachment?.mimeType.startsWith("video/") && (
                                <video
                                  src={attachment?.url}
                                  controls
                                  className="attachment-preview w-full h-auto"
                                ></video>
                              )}
                              {attachment?.mimeType.startsWith("text/") && (
                                <div className="attachment-json p-2 flex flex-col items-center">
                                  <div className="text-xs mt-1 flex flex-col items-center gap-3">
                                    <FaFile fontSize={24} color="grey" />
                                    <pre className="w-[120px] truncate">
                                      {attachment?.filename}
                                    </pre>
                                  </div>
                                </div>
                              )}
                              {attachment?.mimeType.startsWith(
                                "application/"
                              ) && (
                                <div className="attachment-json p-2 flex flex-col items-center">
                                  <div className="text-xs mt-1 flex flex-col items-center gap-3">
                                    <FaFile fontSize={24} color="grey" />
                                    <div className="w-[120px] truncate">
                                      {attachment?.filename}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {!attachment?.mimeType.startsWith("image/") &&
                                !attachment?.mimeType.startsWith("video/") &&
                                !attachment?.mimeType.startsWith("text/") &&
                                !attachment?.mimeType.startsWith(
                                  "application/"
                                ) && (
                                  <div className="attachment-generic p-2 flex flex-col items-center">
                                    <div className="text-xs mt-1 w-[120px] truncate">
                                      {attachment?.filename}
                                    </div>
                                  </div>
                                )}
                              <div className="absolute top-2 right-2">
                                <button
                                  className="bg-gray-200 hover:bg-gray-300 p-1 rounded-full"
                                  onClick={() =>
                                    createDownloadLink(
                                      attachment?.blob,
                                      attachment?.filename
                                    )
                                  }
                                >
                                  <FaArrowDown />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {message?.body?.length > 0 ? (
                      getContent()
                    ) : (
                      <>
                        {message?.body?.contentType === "html" ? (
                          <div
                            className="w-full"
                            dangerouslySetInnerHTML={{
                              __html: message?.body?.content,
                            }}
                          />
                        ) : (
                          <div
                            className="w-full"
                            dangerouslySetInnerHTML={{
                              __html: message?.body?.content,
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </MainHeaderSideBar>
  );
};

export default InboxId;
