import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../store/slices/UserSlice";
import google from "../../assets/googlebtn.svg";
import microsoft from "../../assets/microsoftbtn.svg";
import { FaGoogle, FaMicrosoft } from "react-icons/fa";

const Tabs = () => {
  const { userInfo } = useSelector((state) => {
    return state.user;
  });
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();

  const handleChange = (value) => {
    setCount(count + 1);
    dispatch(updateUserData({ key: "selectedTab", value: value }));
    dispatch(updateUserData({ key: "newChange", value: count + 1 }));
  };

  return (
    <div className="inline-flex relative top-[130px]">
      {userInfo?.type === "login" && (
        <div className="flex flex-col px-[15px] gap-[10px]">
          {" "}
          <div
            style={{
              backgroundColor:
                userInfo?.selectedTab === "google"
                  ? "#E7C801"
                  : "#EAECF0",
            }}
            className="cursor-pointer  w-[50px] h-[50px] rounded-lg flex items-center justify-center"
            onClick={() => handleChange("google")}
          >
            <FaGoogle
              color={
                userInfo?.selectedTab === "google"
                  ? "white"
                  : "#475467"
              }
              fontSize={20}
            />
          </div>
          <div
            style={{
              backgroundColor:
                userInfo?.selectedTab === "microsoft"
                  ? "#E7C801"
                  : "#EAECF0",
            }}
            className="cursor-pointer  w-[50px] h-[50px] rounded-lg flex items-center justify-center"
            onClick={() => handleChange("microsoft")}
          >
            <FaMicrosoft
              color={
                userInfo?.selectedTab === "microsoft"
                  ? "white"
                  : "#475467"
              }
              fontSize={20}
            />
          </div>
        </div>
      )}
      {userInfo?.type === "google" && (
        <div className="flex flex-col px-[15px] gap-[10px]">
          {" "}
          <div
            style={{
              backgroundColor:
                userInfo?.selectedTab === "google"
                  ? "#E7C801"
                  : "#EAECF0",
            }}
            className="cursor-pointer  w-[50px] h-[50px] rounded-lg flex items-center justify-center"
            onClick={() => handleChange("google")}
          >
            <FaGoogle
              color={
                userInfo?.selectedTab === "google"
                  ? "white"
                  : "#475467"
              }
              fontSize={20}
            />
          </div>
        </div>
      )}
      {userInfo?.type === "microsoft" && (
        <div className="flex flex-col px-[15px] gap-[10px]">
          {" "}
          <div
            style={{
              backgroundColor:
                userInfo?.selectedTab === "microsoft"
                  ? "#E7C801"
                  : "#EAECF0",
            }}
            className="cursor-pointer  w-[50px] h-[50px] rounded-lg flex items-center justify-center"
            onClick={() => handleChange("microsoft")}
          >
            <FaMicrosoft
              color={
                userInfo?.selectedTab === "microsoft"
                  ? "white"
                  : "#475467"
              }
              fontSize={20}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tabs;
