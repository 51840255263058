import axios from "axios";

const handleCatch = (error) => {
  if (error.response) {
    if (error.response.status === 422) {
      if (error.response.data.errors) {
        const myObject = error.response.data.errors;
        const firstKey = Object.keys(myObject)[0];
        const firstValue = myObject[firstKey];
        throw new Error(firstValue[0]);
      } else {
        throw new Error(error.response.data.message);
      }
    } else if (error.response.status === 401) {
      // localStorage.clear();
      // window.location.replace(window.location.origin+'/unauthorized')
      // window.location.reload();
    } else {
      throw new Error("An error occurred on the server.");
    }
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
  throw error;
};

export const PaymentStatusService = async (userData) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/payment-status`,
      userData,
      {
        headers: myHeaders,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const MeetingPaymentStatusService = async (userData) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/payment-meeting-status`,
      userData,
      {
        headers: myHeaders,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const SignupUserPaymentStatusService = async (userData) => {
  const token = localStorage.getItem("jwt");
  try {
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/payment-status-user`,
      userData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error); // Assuming handleCatch is a function you have defined for error handling
  }
};

export const DataRemovalRequestService = async (userData) => {
  const token = localStorage.getItem("jwt");
  try {
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/user-data`,
      userData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error); // Assuming handleCatch is a function you have defined for error handling
  }
}

