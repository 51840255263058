import { createSlice } from "@reduxjs/toolkit";



let initialStateFirst={
    userInfo: {
  
    },
    isSignedIn: false,
  };


const userSlice = createSlice({

    name: "user",
    initialState: initialStateFirst,
    reducers: {
      loginUser(state, action) {

        // action.payload.selectedTab="google";
        state.userInfo = action.payload;
      },
      updateUserData(state, action) {
        state.userInfo[action.payload.key] = action.payload.value;
      },
  
    },
    extraReducers(builder) {
   
    },


})



export const { loginUser , updateUserData} = userSlice.actions;
export default userSlice.reducer;