import React, { useState } from "react";
import { WelcomeIcon } from "../../assets/custom-icons";
import ezpzLogo from "../../assets/ezpzLogo.svg";
import ConnectModal from "../../components/modals/ConnectModal";
import { showNotification } from "../../utils/error";
import { useNavigate } from "react-router-dom";
const WelcomePage = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="w-full  px-8 bg-white flex-col justify-center items-center flex">
        <div className="w-full px-8 pt-8 justify-between items-center flex">
          <div className="">
            <img alt="" src={ezpzLogo} />
          </div>
          <button
            onClick={() => {
              localStorage.clear();
              //   instance.logoutRedirect({
              //     postLogoutRedirectUri: "/",
              // });
              showNotification("success", "Logout Successfully");
              setTimeout(() => {
                navigate("/");
              }, 1000);
            }}
            className="px-4 py-2.5 bg-white rounded-lg shadow border border-yellow-600 justify-center items-center gap-1.5 flex"
          >
            <div className="px-0.5 justify-center items-center flex">
              <div className="text-yellow-600 text-base font-semibold  leading-normal">
                Logout
              </div>
            </div>
          </button>
        </div>
        <div className="pt-12 pb-[6px] 2xl:py-12">
          <WelcomeIcon />
        </div>
        <div className="justify-center flex-col items-center flex">
          <div className="text-center text-gray-800 text-[40px] font-bold ">
            Connect Your Email Account
          </div>
          <div className="w-[75%] text-center text-slate-600 text-xl font-normal  leading-loose">
            To get started, please connect your Gmail or Outlook account. This
            will allow you to manage your emails and calendars all in one place,
            making your life easier and more organized.
          </div>
          <span className="pt-4">
            <button
              onClick={() => {
                setIsOpen(true);
              }}
              className="px-[22px] py-4 bg-[#E7C801] rounded-lg shadow border border-[#E7C801] justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-white text-xl font-semibold  leading-7">
                Connect My Email
              </div>
            </button>
          </span>
        </div>
      </div>
      <ConnectModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        microsoft={true}
        google={true}
      />
    </>
  );
};

export default WelcomePage;
