import { Route, Routes, useLocation } from "react-router-dom";
import "./css/App.css";

import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { routeTitles } from "./utils/constants";
import Login from "./containers/auth/login/Login";
import LoginPassword from "./containers/auth/login/LoginPassword";
import ForgotPassword from "./containers/auth/forgot-paasword/ForgotPassword";
import NewPassword from "./containers/auth/setNewPassword/NewPassword";
import AuthRoute from "./routes/AuthRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import Inbox from "./containers/inbox/Inbox";
import InboxId from "./containers/inboxById/InboxId";
import Plans from "./containers/plans/Plans";
import PaidBlockMails from "./containers/blockMails/PaidBlockMails";
import UnpaidBlockMails from "./containers/blockMails/UnpaidBlockMails";
import SuccessPage from "./containers/payment/SuccessPage";
import Signup from "./containers/auth/sign-up/Signup";
import WelcomePage from "./containers/welcome-page/WelcomePage";
// import Calenders from "./containers/calenders/Calenders";
import SignUpUserPaymentPlan from "./containers/plans/SignUpUserPaymentPlan";
import SignupPhoneNo from "./containers/auth/sign-up/SignupPhoneNo";
import Analytics from "./containers/analytics/Analytics";
import DataRemovalPaymentPlan from "./containers/plans/DataRemovalPaymentPlan";
import PrivacyPolicy from "./containers/privacy-policy/PrivacyPolicy";
import TermsOfService from "./containers/privacy-policy/TermsOfService";

export default function App() {
  const location = useLocation();

  useEffect(() => {
    const currentRoute = location.pathname;
    const title = routeTitles[currentRoute] || "EZ PZ";
    document.title = title;

    if (localStorage.getItem("jwt")) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [location]);

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={
            // <AuthRoute>
            <Login />
            // </AuthRoute>
          }
        />
        <Route
          exact
          path="/sign-up"
          element={
            <AuthRoute>
              <Signup />
            </AuthRoute>
          }
        />
        <Route exact path="/login-password" element={<LoginPassword />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/new-password/:id" element={<NewPassword />} />
        <Route
          exact
          path="/privacy-policy"
          element={
            // <AuthRoute>
            <PrivacyPolicy />
            // </AuthRoute>
          }
        />{" "}
        <Route
          exact
          path="/terms"
          element={
            // <AuthRoute>
            <TermsOfService />
            // </AuthRoute>
          }
        />
        <Route
          exact
          path="/connect"
          element={
            <ProtectedRoute>
              <WelcomePage />
            </ProtectedRoute>
          }
        />
        <Route exact path="/set-phone-number" element={<SignupPhoneNo />} />
        <Route
          exact
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Analytics />
            </ProtectedRoute>
          }
        />
        {/* <Route
        exact
        path="/calender"
        element={
          <ProtectedRoute>

            <Calenders />
             </ProtectedRoute>

        }
      /> */}
        <Route
          exact
          path="/inbox"
          element={
            <ProtectedRoute>
              <Inbox />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/inbox/:id"
          element={
            <ProtectedRoute>
              <InboxId />
            </ProtectedRoute>
          }
        />
        <Route exact path="/plans" element={<Plans />} />
        <Route
          exact
          path="/signup-user-paymentplan"
          element={
            <ProtectedRoute>
              <SignUpUserPaymentPlan />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/data-removal-paymentplan"
          element={
            <ProtectedRoute>
              <DataRemovalPaymentPlan />
            </ProtectedRoute>
          }
        />
        <Route exact path="/payment-success" element={<SuccessPage />} />
        <Route
          exact
          path="/paid-mails"
          element={
            <ProtectedRoute>
              <PaidBlockMails />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/unpaid-emails"
          element={
            <ProtectedRoute>
              <UnpaidBlockMails />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/paid-mails/:id"
          element={
            <ProtectedRoute>
              <InboxId />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/unpaid-emails/:id"
          element={
            <ProtectedRoute>
              <InboxId />
            </ProtectedRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </>
  );
}
