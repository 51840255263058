import checkCircle from "../../assets/check-circle.svg";
import Payment from "../payment/Payment";
import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import { showNotification } from "../../utils/error";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../../components/loaders/Loader";

const Plans = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const name = queryParams.get("name");
  const recieverEmail = queryParams.get("receiveremail");
  const accountType = queryParams.get("type");
  const mailId = queryParams.get("mail_id");
  const stripePromise = loadStripe(
    "pk_test_51ORvl9JBlwRUWYLYchDzUJfzN7Wydmttw5QXKYZOgILBYiEZcZkNpuCN8wp8P7d9iR6iplFQK4jB8W7OzGy2hziX00QnM90CS3"
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState();
  const [paymentType, setPaymentType] = useState();
  const [isMeetingPopupOpen, setIsMeetingPopupOpen] = useState(false);
  const [meetingDuration, setMeetingDuration] = useState("0.5");
  const [meetingRate, setmeetingRate] = useState();
  console.log("🚀 ~ Plans ~ meetingRate:", meetingRate);

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const handleMeetingPopupOpen = () => setIsMeetingPopupOpen(true);
  const handleMeetingPopupClose = () => setIsMeetingPopupOpen(false);

  const plans = [
    {
      name: "Unblock Emails",
      feature: "free plan",
      type: "mail",
      monthlyPrice: 2,
      priceCriteria: "per account",
      use: "Basic features for up to 1 account.",
      annualPrice: null, // Not provided in the code
      features: ["Single Account", "$5 Per Account", "Cold Email Blocker"],
    },
    {
      name: "Schedule Meeting",
      monthlyPrice: 250,
      type: "meeting",
      priceCriteria: "per hour",
      use: "Pay for scheduling meetings.",
      annualPrice: null, // Not provided in the code
      feature: "Basic",
      features: ["Scheduling Meeting", "Minimum Meeting Time 1/2 hr"],
    },
  ];

  const handleGetStarted = (plan) => {
    if (plan.type === "meeting") {
      handleMeetingPopupOpen();
    } else {
      setAmount(plan.monthlyPrice);
      setPaymentType(plan.type);
      handleOpen();
    }
  };

  const handlePayClick = () => {
    setAmount(meetingRate);
    setPaymentType("meeting");
    handleMeetingPopupClose();
    handleOpen();
  };
  const fetchRate = async () => {
    const recieverEmail = queryParams.get("receiveremail");
    const accountType = queryParams.get("type");
    const token = localStorage.getItem("jwt");
    try {
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const body = {
        email: recieverEmail,
        type: accountType,
      };
      const response = await axiosInstance.post("/api/get-rate-stripe", body, {
        headers: myHeaders,
      });
      if (response?.status) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setmeetingRate(
          response?.data?.data?.per_hour_rate == 0 ||
            response?.data?.data == null
            ? "5"
            : response?.data?.data?.per_hour_rate
        );
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      showNotification("error", error?.response?.data?.error || error?.message);
    }
  };
  useEffect(() => {
    fetchRate();
  }, []);

  return (
    <>
      <div className="w-full max-h-[100vh] overflow-auto px-2 md:px-4 lg:px-8 py-12 bg-white flex-col justify-start items-center gap-8 inline-flex">
        {isLoading ? (
          <div className="flex justify-center items-center h-[40vh]">
            <Loader />
          </div>
        ) : (
          <>
            <div className="self-stretch px-8 flex-col justify-start items-center gap-8 flex">
              <div className="self-stretch flex-col justify-start items-center gap-6 flex">
                <div className="self-stretch h-24 flex-col justify-start items-start gap-3 flex">
                  <div className="self-stretch text-center text-yellow-600 text-base font-semibold leading-normal">
                    Pricing
                  </div>
                  <div className="self-stretch text-center text-gray-900 text-5xl font-semibold leading-[60px]">
                    Simple, transparent pricing
                  </div>
                </div>
                <div className="self-stretch text-center text-slate-600 text-xl font-normal leading-[30px]">
                  We believe Lorem should be accessible to all companies, no
                  matter the size.
                </div>
              </div>
              <div className="p-1.5 w-[fit-content] align-[center] bg-gray-50 rounded-xl border border-gray-200 justify-start items-center gap-1 inline-flex">
                <div className="px-3 py-2 rounded-md justify-center items-center gap-2 flex">
                  <div className="text-gray-500 text-base font-semibold leading-normal">
                    Payment Plan
                  </div>
                </div>
              </div>
              <div className="self-stretch pb-24 flex-col lg:flex-row flex gap-8 justify-center">
                {plans?.map((plan) => (
                  <div
                    key={plan.feature}
                    className={`px-4 bg-white h-auto rounded-2xl shadow border ${
                      plan.name === "Team"
                        ? "border-yellow-500"
                        : "border-grey-500"
                    } flex-col justify-start items-start inline-flex`}
                  >
                    <div className="self-stretch p-8 border-b border-gray-200 flex-col justify-start items-start gap-8 flex">
                      <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className="self-stretch justify-start items-start gap-4 inline-flex">
                          <div className="grow shrink basis-0 text-slate-600 text-lg font-semibold leading-7">
                            {plan.name}
                          </div>
                        </div>
                        <div className="self-stretch justify-start items-end gap-1 inline-flex">
                          <div className="text-gray-900 text-6xl font-semibold leading-[72px]">
                            $
                            {plan.type === "meeting"
                              ? meetingRate
                              : plan.monthlyPrice}
                          </div>
                          <div className="pb-2 justify-start items-start flex">
                            <div className="text-slate-600 text-base font-medium leading-normal">
                              {plan.priceCriteria}
                            </div>
                          </div>
                        </div>
                        <div className="self-stretch text-slate-600 text-base font-normal leading-normal">
                          {plan.use}
                        </div>
                      </div>
                      <div className="self-stretch flex-col justify-start items-start gap-3 flex">
                        <div
                          onClick={() => handleGetStarted(plan)}
                          className="self-stretch cursor-pointer px-[18px] py-3 bg-yellow-500 rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 inline-flex"
                        >
                          <div className="px-0.5 justify-center items-center flex">
                            <div className="text-white text-base font-semibold leading-normal">
                              Get started
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch px-8 pt-8 pb-10 flex-col justify-start items-start gap-6 flex">
                      <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                        <div className="self-stretch text-gray-900 text-base font-semibold leading-normal">
                          FEATURES
                        </div>
                      </div>
                      <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        {plan.features?.map((txt, ind) => (
                          <div
                            className="self-stretch justify-start items-start gap-3 inline-flex"
                            key={`${txt}-${ind}`}
                          >
                            <div className="w-6 h-6 rounded-xl justify-center items-center flex">
                              <img
                                src={checkCircle}
                                alt=""
                                className="w-6 h-6 relative flex-col justify-start items-start flex"
                              />
                            </div>
                            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                              <div className="self-stretch text-slate-600 text-[12px] xl:text-[16px] font-normal leading-normal">
                                {txt}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>

      {/* Meeting Duration Popup */}
      {isMeetingPopupOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 w-[500px] ">
            <h2 className="text-2xl font-semibold mb-6 ">
              Choose Meeting Duration
            </h2>
            <select
              value={meetingDuration}
              onChange={(e) => setMeetingDuration(e.target.value)}
              className="mb-6 p-2 border rounded-md w-[100%] cursor-pointer"
            >
              <option value="0.5">1/2 hr</option>
              <option value="1">1 hr</option>
              <option value="1.5">1.5 hr</option>
              <option value="2">2 hr</option>
              <option value="2.5">2.5 hr</option>
              <option value="3">3 hr</option>
              <option value="3.5">3.5 hr</option>
              <option value="4">4 hr</option>
              <option value="4.5">4.5 hr</option>
              <option value="5">5 hr</option>
            </select>
            <div className="flex justify-end gap-4 mt-4">
              <button
                onClick={handleMeetingPopupClose}
                className="px-4 py-2 bg-gray-300 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handlePayClick}
                className="px-6 py-2 bg-yellow-500 text-white rounded-md"
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      )}
      {/* stripr payment */}
      <Elements stripe={stripePromise}>
        <Payment
          isOpen={isModalOpen}
          onClose={handleClose}
          name={name}
          email={email}
          accountType={accountType}
          paymentAmount={amount * meetingDuration}
          type={paymentType}
          recieverEmail={recieverEmail}
          mailId={mailId}
        />
      </Elements>
    </>
  );
};

export default Plans;
