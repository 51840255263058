import React, { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  Box,
  FormControl,
  FormLabel,
  Button as ChakraButton,
  VStack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ChakraProvider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  DataRemovalRequestService,
  SignupUserPaymentStatusService,
} from "../../services/Service";
import { useSelector } from "react-redux";
const CheckoutForm = ({ handleClose, userData, amount }) => {
  const { userInfo } = useSelector((state) => {
    return state.user;
  });
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const toast = useToast();
  const nav = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      // notifyBackend("failed", null);
    } else {
      const selectedData = userInfo?.social_login?.filter((data)=>data.type===userInfo?.selectedTab)
      const response2 = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/create-customer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: selectedData && selectedData[0]?.email,
            name:
            selectedData &&
              `${selectedData[0]?.given_name} ${selectedData[0]?.family_name}`,
            customer: "signup_user",
          }),
        }
      );
      const createUserResponse = await response2.json();

      const amountInCents = amount * 100; // Convert amount to cents

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payment_method_id: paymentMethod.id,
            customer_id:
              createUserResponse?.data?.id ||
              createUserResponse?.data?.stripe_customer_id,
            amount: amountInCents,
          }),
        }
      );

      const paymentIntentResponse = await response.json();

      const { error: confirmError, paymentIntent } =
        await stripe.confirmCardPayment(paymentIntentResponse.client_secret);

      if (confirmError) {
        setError(confirmError.message);
        const response = await SignupUserPaymentStatusService({
          email: selectedData && selectedData[0]?.email,
          status: "fail",
          message: confirmError.message,
          user_id: selectedData && selectedData[0]?.user_id,
          social_id: selectedData && selectedData[0]?.id,
          customer_id:
            createUserResponse?.data?.id ||
            createUserResponse?.data?.stripe_customer_id,
        });
        setLoading(false);
        toast({
          title: "Error",
          description: confirmError.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        // notifyBackend("failed", paymentIntentResponse.id);
      } else {
        switch (paymentIntent.status) {
          case "requires_payment_method":
            setError("Payment method required. Please try again.");

            break;
          case "requires_confirmation":
            setError("Payment requires confirmation. Please try again.");

            break;
          case "requires_action":
            setError(
              "Further user action required. Please complete the authentication."
            );

            break;
          case "processing":
            setSuccess("Payment is being processed. Please wait.");
            break;
          case "requires_capture":
            setSuccess("Payment authorized but requires capture.");
            break;
          case "canceled":
            setError("Payment has been canceled.");
            const response2 = await SignupUserPaymentStatusService({
              email: selectedData && selectedData[0]?.email,
              status: "fail",
              message: "Payment has been canceled.",
              user_id: selectedData && selectedData[0]?.user_id,
              social_id: selectedData && selectedData[0]?.id,
              customer_id:
                createUserResponse?.data?.id ||
                createUserResponse?.data?.stripe_customer_id,
            });
            break;
          case "succeeded":
            const response3 = await SignupUserPaymentStatusService({
              email: selectedData && selectedData[0]?.email,
              status: "success",
              message: "Payment successful!",
              user_id: selectedData && selectedData[0]?.user_id,
              social_id: selectedData && selectedData[0]?.id,
              customer_id:
                createUserResponse?.data?.id ||
                createUserResponse?.data?.stripe_customer_id,
            });
            if (amount === 30) {
              const res = await DataRemovalRequestService({
                id: selectedData && selectedData[0]?.user_id,
                type: selectedData && selectedData[0]?.type,
              });
            }
            setSuccess("Payment successful!");
            handleClose(); // Close the modal on success
            nav("/inbox");
            toast({
              title: "Success",
              description: "Payment successful!",
              status: "success",
              duration: 9000,
              isClosable: true,
              position: "top-right",
            });
            // notifyBackend("success", paymentIntent.id);
            break;
          default:
            setError("Unknown payment status.");

            break;
        }
        setLoading(false);
      }
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#4F5B76",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  return (
    <Box as="form" onSubmit={handleSubmit} p="4">
      <VStack spacing={4}>
        <FormControl>
          <FormLabel color="#4F5B76">Card number</FormLabel>
          <Box
            as={CardNumberElement}
            padding="10px"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
            options={cardElementOptions}
          />
        </FormControl>
        <FormControl>
          <FormLabel color="#4F5B76">Expiry</FormLabel>
          <Box
            as={CardExpiryElement}
            padding="10px"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
            options={cardElementOptions}
          />
        </FormControl>
        <FormControl>
          <FormLabel color="#4F5B76">CVC</FormLabel>
          <Box
            as={CardCvcElement}
            padding="10px"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
            options={cardElementOptions}
          />
        </FormControl>
        <ChakraButton
          boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
          color="white"
          type="submit"
          bg="#E7C801"
          _hover={{}}
          width="full"
          isLoading={loading}
          isDisabled={!stripe || loading}
        >
          Pay
        </ChakraButton>
        {error && <Box color="red.500">{error}</Box>}
        {success && <Box color="green.500">{success}</Box>}
      </VStack>
    </Box>
  );
};

const SignUpUserPayment = ({ isOpen, onClose, userData, paymentAmount }) => {
  return (
    <ChakraProvider>
      <Modal
        isCentered
        scrollBehavior={"inside"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CheckoutForm
              handleClose={onClose}
              userData={userData}
              amount={paymentAmount}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default SignUpUserPayment;
