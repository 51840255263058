import React, { useCallback, useState } from "react";

import loginSec from "../../../assets/login-sec.svg";
import logo from "../../../assets/logo.svg";
import logoGoogle from "../../../assets/google.svg";
import logoMicrosoft from "../../../assets/microsoft.svg";
import { Link, useNavigate } from "react-router-dom";
import { LoginSocialGoogle, LoginSocialMicrosoft } from "reactjs-social-login";
import axios from "axios";
import { showNotification } from "../../../utils/error";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import LoginPassword from "./LoginPassword";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../store/slices/UserSlice";

const base_url = process.env.REACT_APP_BASE_URL;
const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
const Login = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        "Invalid email address"
      )
      .required("Email is required"),
  });

  const initialValues = {
    email: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isResp, setIsResp] = useState(false);
  const [isRespMic, setIsRespMic] = useState(false);
  const Com = () => {
    return (
      <button
        disabled={isRespMic}
        className=" px-16 py-2.5 bg-white rounded-lg shadow border border-gray-300  gap-3 inline-flex"
      >
        {isRespMic ? (
          <div className="px-[89px]">
            <ButtonLoader />
          </div>
        ) : (
          <>
            <div className=" p-0.5 justify-center items-center flex">
              <img src={logoMicrosoft} alt="ok" />
            </div>
            <div className="text-slate-700 text-base font-semibold  leading-normal">
              Log in with Microsoft
            </div>
          </>
        )}
      </button>
    );
  };

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  const handleLogin = async (data) => {
    try {
      console.log(data, "data");
      const body = {
        ...data,
        type: "google",
      };
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const response = await axios.post(`${base_url}/api/login`, body, {
        headers: myHeaders,
      });
      if (response?.data) {
        response.data.data.selectedTab = "google";
        dispatch(loginUser(response.data.data));
        const userData = { ...response?.data, ...response?.data?.data };
        localStorage.setItem("userData", JSON.stringify(response?.data?.data));
        localStorage.setItem("jwt", response?.data?.token);

        setTimeout(() => {
          setIsResp(false);
          if (response?.data?.data?.phone_number !== null) {
            if (response?.data?.data?.social_login[0]?.is_paid === 1) {
              navigate("/inbox");
              showNotification("success", "Logged In Successfully");
            } else {
              navigate("/signup-user-paymentplan");
              showNotification("success", "Logged In Successfully");
            }
          } else {
            navigate("/set-phone-number");
            showNotification("success", "Logged In Successfully");
          }
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setIsResp(false);
      }, 1000);
      showNotification(
        "error",
        error?.response?.data?.message || error?.message
      );
    }
  };

  const authHandler = async (responseData) => {
    setIsRespMic(true);

    let userData = { picture: "", email: "", name: "", type: "" };
    if (responseData && responseData.access_token) {
      // navigate('/inbox')
      userData.email = responseData?.mail || "";
      userData.name = responseData?.displayName || "";
      userData.access_token = responseData?.access_token;

      try {
        const body = {
          ...responseData,
          type: "microsoft",
        };

        const myHeaders = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        const response = await axios.post(`${base_url}/api/login`, body, {
          headers: myHeaders,
        });
        if (response?.data) {
          userData.type = response?.data?.type;
          localStorage.setItem(
            "userData",
            JSON.stringify(response?.data?.data)
          );
          localStorage.setItem("jwt", response?.data?.token);
          // showNotification("success", "Logged In Successfully");
          response.data.data.selectedTab = "microsoft";
          dispatch(loginUser(response.data.data));
          setTimeout(() => {
            setIsRespMic(false);
            if (response?.data?.data?.phone_number !== null) {
              if (response?.data?.data?.social_login[0]?.is_paid === 1) {
                navigate("/inbox");
                showNotification("success", "Logged In Successfully");
              } else {
                navigate("/signup-user-paymentplan");
                showNotification("success", "Logged In Successfully");
              }
            } else {
              navigate("/set-phone-number");
              showNotification("success", "Logged In Successfully");
            }
          }, 1000);
        }
      } catch (error) {
        setTimeout(() => {
          setIsRespMic(false);
        }, 1000);
        showNotification(
          "error",
          error?.response?.data?.message || error?.message
        );
      }
    }
  };

  const onLogoutSuccess = useCallback(() => {
    setTimeout(() => {
      setIsResp(false);
    }, 1000);
  }, []);
  const [isEmail, setIsEmail] = useState(false);
  const [email, setEmail] = useState("");
  const onSubmit = async (values, { setSubmitting }) => {
    setIsEmail(true);
    setSubmitting(false);
    setEmail(values?.email);
  };
  return (
    <>
      {isEmail ? (
        <>
          <LoginPassword
            email={email}
            setEmail={setEmail}
            setIsEmail={setIsEmail}
          />
        </>
      ) : (
        <div className="w-full max-h-[100vh] bg-white  items-center inline-flex">
          <div className=" w-[50%] grow shrink basis-0 self-stretch flex-col justify-center items-center inline-flex">
            <div className="self-stretch  px-8 flex-col justify-start items-center flex">
              <div className=" flex-col justify-start items-center gap-8 flex">
                <div className="self-stretch h-[170px] flex-col justify-start items-center gap-6 flex">
                  <div className="w-12 h-12 relative">
                    <div className="w-12 h-12 left-0 top-0 absolute bg-zinc-300 rounded-full float-right" />
                    <img
                      alt="ok"
                      className="w-[121.52px] h-[48.34px] left-[-0.17px] top-[-0.17px] absolute"
                      src={logo}
                    />
                  </div>
                  <div className="self-stretch h-[98px] flex-col justify-start items-center gap-3 flex">
                    <div className="self-stretch text-center text-gray-900 text-3xl font-semibold  leading-[38px]">
                      Welcome back
                    </div>
                    <div className="w-[261px] text-center text-slate-600 text-base font-normal  leading-normal">
                      Email Management Made Easy, Lemon Squeezy!
                    </div>
                  </div>
                </div>
                <div className="self-stretch rounded-xl flex-col justify-start items-center gap-6 flex">
                  <div className="self-stretch  flex-col justify-center items-center gap-3 flex">
                    <LoginSocialGoogle
                      client_id={client_id}
                      onLoginStart={onLoginStart}
                      onLogoutSuccess={onLogoutSuccess}
                      onResolve={({ provider, data }) => {
                        setIsResp(true);
                        handleLogin(data);
                      }}
                      onReject={(err) => {
                        setTimeout(() => {
                          setIsResp(false);
                        }, 1000);
                      }}
                      scope="openid email profile https://mail.google.com/ https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar"
                      // typeResponse="accessToken"
                      access_type="offline"
                      // prompt='force'
                    >
                      <button
                        disabled={isResp}
                        className="self-stretch px-[4.8rem] py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex"
                      >
                        {isResp ? (
                          <div className="px-20">
                            <ButtonLoader />
                          </div>
                        ) : (
                          <>
                            <img
                              src={logoGoogle}
                              alt="ok"
                              className="w-5 h-5 relative"
                            />
                            <div className="text-slate-700 text-base font-semibold  leading-normal">
                              Log in with Google
                            </div>
                          </>
                        )}
                      </button>
                    </LoginSocialGoogle>
                    <LoginSocialMicrosoft
                      client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ""}
                      redirect_uri={"https://dylnn.aidevlab.com"}
                      onLoginStart={onLoginStart}
                      onResolve={({ provider, data }) => {
                        console.log(data);
                        authHandler(data);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                      access_type="offline"
                      prompt="select_account"
                      response_type="code"
                      scope="Calendars.Read Mail.Read Mail.ReadWrite email profile openid User.Read Files.ReadWrite.AppFolder Files.ReadWrite.All Files.Read.All Files.SelectedOperations.Selected MailboxSettings.Read MailboxSettings.ReadWrite AccessReview.Read.All AccessReview.ReadWrite.All AccessReview.ReadWrite.Membership"
                    >
                      <Com isRespMic={isRespMic} />
                    </LoginSocialMicrosoft>
                  </div>
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="grow shrink basis-0 h-px bg-gray-200" />
                    <div className="text-center text-slate-600 text-sm font-medium font-['Inter'] leading-tight">
                      or continue with your email
                    </div>
                    <div className="grow shrink basis-0 h-px bg-gray-200" />
                  </div>
                  <div className="w-full flex-col  gap-4 flex">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <div className="pb-4">
                            <div className="text-slate-700 text-sm font-medium  leading-tight py-2">
                              Email *
                            </div>
                            <div className=" w-full    px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300  items-center flex">
                              <Field
                                type="text"
                                name="email"
                                placeholder="Enter your email"
                                className="w-full text-[#667085] text-base font-normal  leading-normal bg-white border-none outline-none"
                              />
                            </div>
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-red-600 text-[14px] "
                            />{" "}
                          </div>
                          <button
                            disabled={isSubmitting}
                            className={`w-full py-2.5  bg-[#E7C801] rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 inline-flex`}
                          >
                            <div className="justify-center items-center flex">
                              <div className="text-white text-base font-semibold  leading-normal">
                                {isSubmitting ? (
                                  <div className="">
                                    <ButtonLoader />
                                  </div>
                                ) : (
                                  "Log In"
                                )}
                              </div>
                            </div>
                          </button>
                        </Form>
                      )}
                    </Formik>
                    <div className="self-stretch justify-center flex">
                      <div className=" text-slate-600 text-sm font-normal  leading-tight text-center">
                        Don't have account?{" "}
                        <Link to={"/sign-up"}>
                          {" "}
                          <span className="text-[#B49C01] font-bold">
                            Sign up
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[50%] max-h-[100vh]">
            <img
              src={loginSec}
              className="h-[100vh] w-full object-cover"
              alt=""
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
