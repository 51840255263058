import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import background from "../../../assets/background.svg";
import BackIcon from "../../../components/BackIcon";

import { showNotification } from "../../../utils/error";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";
import axios from "axios";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { EyeIcon, UnHideEyeIcon } from "../../../assets/custom-icons";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../store/slices/UserSlice";
const base_url = process.env.REACT_APP_BASE_URL;

const LoginPassword = ({ setIsEmail, email, setEmail }) => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
  });

  const initialValues = {
    password: "",
  };
  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const body = {
        email: email || null,
        password: values?.password || null,
      };
      const response = await axios.post(base_url + "/api/emailLogin", body);
      if (response?.data?.token) {
        localStorage.setItem("userData", JSON.stringify(response?.data?.data));
        localStorage.setItem("jwt", response?.data?.token);
        // login(response?.data?.body?.token);
        response.data.data.selectedTab = response?.data?.data?.social_login[0]?.type;
        dispatch(loginUser(response.data.data));
        if (response?.data?.data?.social_login?.length > 0) {
          if (response?.data?.data?.social_login[0]?.is_paid === 1) {
            navigate("/inbox");
          } else {
            navigate("/signup-user-paymentplan");
          }
        } else {
          navigate("/connect");
        }

        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("success", response?.data?.message);
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("error", response?.data?.message);
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitting(false);
      }, 2000);
      showNotification(
        "error",
        error?.response?.data?.message || error?.message
      );
    }
  };
  return (
    <div className="w-full h-[100vh] px-8   bg-white flex-col justify-center items-center  flex">
      <div
        className="w-[44%] h-[100vh]  px-8 flex-col justify-center items-center gap-8 flex"
        style={{ background: `url(${background})` }}
      >
        <div className="w-[70%] flex-col justify-center items-center gap-8 flex">
          <div className="  flex-col justify-center items-center gap-6 flex">
            <div className="w-12 h-12 relative">
              <img className="h-[48.34px]  absolute" src={logo} alt="ok" />
            </div>
            <div className=" flex-col  gap-3 flex">
              <div className=" text-center text-gray-900 text-3xl font-semibold  leading-[38px]">
                Enter the password
              </div>
              <div className=" text-center text-slate-600 text-base font-normal  leading-normal">
                Hey! Welcome back
              </div>
            </div>
          </div>
          <div className="w-full">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="w-full">
                    <div className="pb-4">
                      <div className="text-slate-700 text-sm font-medium  leading-tight py-2">
                        Password *
                      </div>
                      <div className=" w-full    px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300  items-center flex">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Enter your password"
                          className="w-full text-[#667085] text-base font-normal  leading-normal bg-white border-none outline-none"
                        />
                        <span className="pl-2">
                          {showPassword ? (
                            <EyeIcon
                              onClick={togglePasswordVisibility}
                              className="cursor-pointer"
                            />
                          ) : (
                            <UnHideEyeIcon
                              onClick={togglePasswordVisibility}
                              className="cursor-pointer"
                            />
                          )}
                        </span>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-600 text-[14px] "
                      />{" "}
                    </div>
                    <button
                      disabled={isSubmitting}
                      className={`w-full py-2.5  bg-[#E7C801] rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 inline-flex`}
                    >
                      <div className="justify-center items-center flex">
                        <div className="text-white text-base font-semibold  leading-normal">
                          {isSubmitting ? (
                            <div className="">
                              <ButtonLoader />
                            </div>
                          ) : (
                            "Log In"
                          )}
                        </div>
                      </div>
                    </button>
                    {/* <div className=" h-[52px] flex-col justify-start items-start gap-3 flex">
                        <div className=" justify-start items-start gap-2 inline-flex">
                            <div className="w-5 h-5 relative bg-gray-300 rounded-[10px]" />
                            <div className="grow shrink basis-0 text-slate-600 text-sm font-normal  leading-tight">Must be at least 8 characters</div>
                        </div>
                        <div className=" justify-start items-start gap-2 inline-flex">
                            <div className="w-5 h-5 relative bg-gray-300 rounded-[10px]" />
                            <div className="grow shrink basis-0 text-slate-600 text-sm font-normal  leading-tight">Must contain one special character</div>
                        </div>
                    </div> */}
                  </div>
                  <div className=" flex justify-between">
                    <div></div>
                    {/* <div className="grow shrink basis-0 h-5 justify-start items-start gap-2 flex">
                        <div className="pt-0.5 justify-center items-center flex">
                            <div className="w-4 h-4 relative rounded border border-gray-300" />
                        </div>
                        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                            <div className=" text-slate-700 text-sm font-medium  leading-tight">Remember me</div>
                        </div>
                    </div> */}
                    <Link
                      to="/forgot-password"
                      className="pt-4 text-yellow-600 text-sm font-semibold  leading-tight"
                    >
                      Forgot password
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className=" justify-center items-start gap-1 inline-flex">
            <button
              onClick={() => {
                setIsEmail(false);
                setEmail("");
              }}
              className="justify-center items-center gap-1.5 flex"
            >
              <BackIcon />

              <div className="text-slate-600 text-sm font-semibold  leading-tight">
                Go back
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPassword;
