import React, { useCallback, useState } from "react";
import loginSec from "../../../assets/login-sec.svg";
import logo from "../../../assets/logo.svg";
import logoGoogle from "../../../assets/google.svg";
import logoMicrosoft from "../../../assets/microsoft.svg";
import { Link, useNavigate } from "react-router-dom";
import { LoginSocialGoogle, LoginSocialMicrosoft } from "reactjs-social-login";
import axios from "axios";
import { showNotification } from "../../../utils/error";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { EyeIcon, UnHideEyeIcon } from "../../../assets/custom-icons";
import PhoneInput from "react-phone-input-2";

const base_url = process.env.REACT_APP_BASE_URL;
const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(
        /^[A-Za-z0-9]+([-_' ]?[A-Za-z0-9]+)*( [A-Za-z0-9]+([-_' ]?[A-Za-z0-9]+)*)*$/,
        "Special characters and extra spaces are not allowed"
      ),
    email: Yup.string()
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        "Invalid email address"
      )
      .required("Email is required"),
    password: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/~\\-])[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?/~\\-]*$/,
        "At least one special character and no spaces."
      ),
    phone_number: Yup.string().required("Phone number is required"),
  });

  const initialValues = {
    name: "",
    email: "",
    password: "",
    phone_number: "",
    country_code: "",
  };

  const navigate = useNavigate();
  const [isResp, setIsResp] = useState(false);
  const [isRespMic, setIsRespMic] = useState(false);

  const Com = () => {
    return (
      <button
        disabled={isRespMic}
        className="px-16 py-2.5 bg-white rounded-lg shadow border border-gray-300 gap-3 inline-flex"
      >
        {isRespMic ? (
          <div className="px-[89px]">
            <ButtonLoader />
          </div>
        ) : (
          <>
            <div className="p-0.5 justify-center items-center flex">
              <img src={logoMicrosoft} alt="ok" />
            </div>
            <div className="text-slate-700 text-base font-semibold leading-normal">
              Log in with Microsoft
            </div>
          </>
        )}
      </button>
    );
  };

  const onLoginStart = useCallback(() => {}, []);

  const handleLogin = async (data) => {
    try {
      const body = {
        ...data,
        type: "google",
      };
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const response = await axios.post(`${base_url}/api/login`, body, {
        headers: myHeaders,
      });
      if (response?.data) {
        const userData = { ...response?.data, ...response?.data?.data };
        localStorage.setItem("userData", JSON.stringify(response?.data?.data));
        localStorage.setItem("jwt", response?.data?.token);

        setTimeout(() => {
          setIsResp(false);
          if (response?.data?.data?.phone_number !== null) {
            if (response?.data?.data?.social_login[0]?.is_paid === 1) {
              navigate("/inbox");
              showNotification("success", "Logged In Successfully");
            } else {
              navigate("/signup-user-paymentplan");
              showNotification("success", "Logged In Successfully");
            }
          } else {
            navigate("/set-phone-number");
            showNotification("success", "Logged In Successfully");
          }
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setIsResp(false);
      }, 1000);
      showNotification(
        "error",
        error?.response?.data?.message || error?.message
      );
    }
  };

  const authHandler = async (responseData) => {
    setIsRespMic(true);

    let userData = { picture: "", email: "", name: "", type: "" };
    if (responseData && responseData.access_token) {
      userData.email = responseData?.mail || "";
      userData.name = responseData?.displayName || "";
      userData.access_token = responseData?.access_token;

      try {
        const body = {
          ...responseData,
          type: "microsoft",
        };

        const myHeaders = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        const response = await axios.post(`${base_url}/api/login`, body, {
          headers: myHeaders,
        });
        if (response?.data) {
          userData.type = response?.data?.type;
          localStorage.setItem(
            "userData",
            JSON.stringify(response?.data?.data)
          );
          localStorage.setItem("jwt", response?.data?.token);

          setTimeout(() => {
            setIsRespMic(false);
            if (response?.data?.data?.phone_number !== null) {
              if (response?.data?.data?.social_login[0]?.is_paid === 1) {
                navigate("/inbox");
                showNotification("success", "Logged In Successfully");
              } else {
                navigate("/signup-user-paymentplan");
                showNotification("success", "Logged In Successfully");
              }
            } else {
              navigate("/set-phone-number");
              showNotification("success", "Logged In Successfully");
            }
          }, 1000);
        }
      } catch (error) {
        setTimeout(() => {
          setIsRespMic(false);
        }, 1000);
        showNotification(
          "error",
          error?.response?.data?.message || error?.message
        );
      }
    }
  };

  const onLogoutSuccess = useCallback(() => {
    setTimeout(() => {
      setIsResp(false);
    }, 1000);
  }, []);

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const body = {
        name: values?.name || null,
        email: values?.email || null,
        password: values?.password || null,
        phone_number: values?.phone_number || null,
        country_code: values?.country_code || null,
      };
      const response = await axios.post(base_url + "/api/signUp", body);
      if (response?.data?.token) {
        localStorage.setItem("userData", JSON.stringify(response?.data?.data));
        localStorage.setItem("jwt", response?.data?.token);
        navigate("/connect");
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("success", response?.data?.message);
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("error", response?.data?.message);
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitting(false);
      }, 2000);
      showNotification(
        "error",
        error?.response?.data?.message || error?.message
      );
    }
  };

  return (
    <div className="w-full min-h-[100vh] bg-white items-center flex">
      <div className="w-[50%] max-h-[100vh] overflow-auto flex-col justify-center items-center flex">
        <div className="px-8 flex-col items-center flex">
          <div className="flex-col items-center gap-6 flex">
            <div className="flex-col items-center gap-4 flex pt-16 2xl:pt-4">
              <div className="w-12 h-12 relative">
                <img alt="ok" className="w-[121.52px] h-[48.34px]" src={logo} />
              </div>
              <div className="text-center text-[#101828] text-3xl font-semibold leading-[20px]">
                Sign up
              </div>
            </div>
            <div className="self-stretch rounded-xl flex-col items-center gap-4 flex">
              <div className="w-full flex-col gap-2 flex">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form>
                      <div>
                        <div className="text-slate-700 text-sm font-medium leading-tight py-2">
                          Name *
                        </div>
                        <div className="w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 items-center flex">
                          <Field
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            className="grow shrink basis-0 text-[#667085] text-base font-normal leading-normal bg-white border-none outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red-600 text-[14px]"
                        />
                      </div>
                      <div className="">
                        <div className="text-slate-700 text-sm font-medium leading-tight py-2">
                          Email *
                        </div>
                        <div className="w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 items-center flex">
                          <Field
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            className="grow shrink basis-0 text-[#667085] text-base font-normal leading-normal bg-white border-none outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-600 text-[14px]"
                        />
                      </div>
                      <div className="pb-2">
                        <div className="text-slate-700 text-sm font-medium leading-tight py-2">
                          Password *
                        </div>
                        <div className="w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 items-center flex">
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Enter your password"
                            className="w-full text-[#667085] text-base font-normal leading-normal bg-white border-none outline-none"
                          />
                          <span className="pl-2">
                            {showPassword ? (
                              <EyeIcon
                                onClick={togglePasswordVisibility}
                                className="cursor-pointer"
                              />
                            ) : (
                              <UnHideEyeIcon
                                onClick={togglePasswordVisibility}
                                className="cursor-pointer"
                              />
                            )}
                          </span>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-600 text-[14px]"
                        />
                      </div>
                      <div className="pb-2">
                        <div className="text-slate-700 text-sm font-medium leading-tight py-2">
                          Phone Number *
                        </div>
                        <div className="w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 items-center flex">
                          <PhoneInput
                            country={"us"}
                            value={initialValues.phone_number}
                            onChange={(phone, country) => {
                              setFieldValue(
                                "phone_number",
                                phone.slice(country.dialCode.length)
                              );
                              setFieldValue("country_code", country.dialCode);
                            }}
                            inputStyle={{
                              width: "100%",
                              border: "none",
                              outline: "none",
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="phone_number"
                          component="div"
                          className="text-red-600 text-[14px]"
                        />
                      </div>
                      <button
                        disabled={isSubmitting}
                        className={`w-full py-2.5 bg-[#E7C801] rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 mt-3 inline-flex`}
                      >
                        <div className="justify-center items-center flex">
                          <div className="text-white text-base font-semibold leading-normal">
                            {isSubmitting ? (
                              <div className="">
                                <ButtonLoader />
                              </div>
                            ) : (
                              "Sign Up"
                            )}
                          </div>
                        </div>
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-px bg-gray-200" />
                <div className="text-center text-slate-600 text-sm font-medium leading-tight">
                  or
                </div>
                <div className="grow shrink basis-0 h-px bg-gray-200" />
              </div>
              <div className="self-stretch flex-col justify-center items-center gap-3 flex">
                <LoginSocialGoogle
                  client_id={client_id}
                  onLoginStart={onLoginStart}
                  onLogoutSuccess={onLogoutSuccess}
                  onResolve={({ provider, data }) => {
                    setIsResp(true);
                    handleLogin(data);
                  }}
                  onReject={(err) => {
                    setTimeout(() => {
                      setIsResp(false);
                    }, 1000);
                  }}
                  scope="openid email profile https://mail.google.com/ https://www.googleapis.com/auth/gmail.readonly"
                  access_type="offline"
                >
                  <button
                    disabled={isResp}
                    className="self-stretch px-[4.8rem] py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex"
                  >
                    {isResp ? (
                      <div className="px-20">
                        <ButtonLoader />
                      </div>
                    ) : (
                      <>
                        <img
                          src={logoGoogle}
                          alt="ok"
                          className="w-5 h-5 relative"
                        />
                        <div className="text-slate-700 text-base font-semibold leading-normal">
                          Log in with Google
                        </div>
                      </>
                    )}
                  </button>
                </LoginSocialGoogle>
                <LoginSocialMicrosoft
                  client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ""}
                  redirect_uri={"https://dylnn.aidevlab.com"}
                  onLoginStart={onLoginStart}
                  onResolve={({ provider, data }) => {
                    console.log(data);
                    authHandler(data);
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                  access_type="offline"
                  prompt="select_account"
                  response_type="code"
                  scope="Calendars.Read Mail.Read Mail.ReadWrite email profile openid User.Read Files.ReadWrite.AppFolder Files.ReadWrite.All Files.Read.All Files.SelectedOperations.Selected MailboxSettings.Read MailboxSettings.ReadWrite AccessReview.Read.All AccessReview.ReadWrite.All AccessReview.ReadWrite.Membership"
                >
                  <Com isRespMic={isRespMic} />
                </LoginSocialMicrosoft>
              </div>
              <div className="self-stretch justify-center flex">
                <div className="text-slate-600 text-sm font-normal leading-tight text-center pb-4 2xl:pb-0">
                  Already have an account?{" "}
                  <Link to={"/"}>
                    <span className="text-[#B49C01] font-bold">Log in</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[50%] min-h-[100vh] overflow-hidden">
        <img src={loginSec} className="h-[100vh] w-full object-cover" alt="" />
      </div>
    </div>
  );
};

export default Signup;
