import React from "react";
import ezpzLogo from "../../assets/ezpzLogo.svg";
import {
  DateIconDark,
  InboxIcon,
  LogoutIcon,
  NotificationIcon,
  PaidMailIcon,
  PencilIcon,
  SearchIcon,
  SettingIcon,
  UnPaidIcon,
  AnalyticsIcon,
} from "../../assets/custom-icons";
import { showNotification } from "../../utils/error";
import profile from "../../assets/profile_mask2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MyTabs from "./Tabs";

const MainHeaderSideBar = ({ children, headingText }) => {
  const location = useLocation();
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : {};
  const navigate = useNavigate();

  return (
    <div className="w-full flex  overflow-hidden bg-white">
      <MyTabs />
      <div className="w-[20%] relative max-h-[100vh] overflow-auto pt-8 flex-col justify-start items-start gap-6 inline-flex">
        <div className="pl-6 relative flex-col justify-start items-start flex ">
          <img alt="" src={ezpzLogo} />
        </div>
        {/* <div className="self-stretch h-4 px-6 flex-col justify-start items-start flex">
          <button className="self-stretch px-4 py-2.5 bg-yellow-500 rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 inline-flex">
            <PencilIcon />
            <div className="text-white text-base font-semibold  leading-normal">
              New Mail
            </div>
          </button>
        </div> */}
        <div className=" mt-[40px] self-stretch grow shrink basis-0 px-4 flex-col justify-start items-start gap-2 flex">
          <Link
            to={"/dashboard"}
            className={`${
              location.pathname.includes("/dashboard")
                ? "bg-gray-200"
                : "bg-gray-50"
            } w-full h-10 px-3 py-2  rounded-md justify-start items-center gap-2 inline-flex`}
          >
            <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
              <AnalyticsIcon />
              <div className="text-gray-900 text-base font-semibold  leading-normal">
                Dashboard
              </div>
            </div>
          </Link>
          <Link
            to="/inbox"
            className={`${
              location.pathname.includes("/inbox")
                ? "bg-gray-200"
                : "bg-gray-50"
            } w-full self-stretch h-10 px-3 py-2  rounded-md`}
          >
            <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
              <InboxIcon />
              <div className="text-gray-900 text-base font-semibold  ">
                Inbox
              </div>
            </div>
          </Link>
          <Link
            to={"/paid-mails"}
            className={`${
              location.pathname.includes("/paid-mails")
                ? "bg-gray-200"
                : "bg-gray-50"
            } w-full h-10 px-3 py-2  rounded-md justify-start items-center gap-2 inline-flex`}
          >
            <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
              <PaidMailIcon />
              <div className="text-gray-900 text-base font-semibold  leading-normal">
                Paid block mails
              </div>
            </div>
          </Link>
          <Link
            to={"/unpaid-emails"}
            className={`${
              location.pathname.includes("/unpaid-emails")
                ? "bg-gray-200"
                : "bg-gray-50"
            } w-full h-10 px-3 py-2  rounded-md justify-start items-center gap-2 inline-flex`}
          >
            <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
              <UnPaidIcon />
              <div className="text-gray-900 text-base font-semibold  leading-normal">
                Unpaid block mails
              </div>
            </div>
          </Link>

          {/* <Link to={'/calender'} className={`${location.pathname.includes('/calender')?"bg-gray-200":"bg-gray-50"} w-full h-10 px-3 py-2  rounded-md justify-start items-center gap-2 inline-flex`}>
    <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
        <DateIconDark  />
        <div className="text-gray-900 text-base font-semibold  leading-normal">Calenders</div>
    </div>
</Link> */}
          {/* <button className="self-stretch h-10 px-3 py-2 bg-gray-50 rounded-md justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
              <SentIcon />
              <div className="text-slate-700 text-base font-semibold  leading-normal">
                Sent
              </div>
            </div>
          </button> */}
          {/* <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1 flex">
            <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-2 flex" ref={moreRef}>
              <button         onClick={handleToggle}
 className="self-stretch px-3 py-2 bg-gray-50 rounded-md justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                  <MoreIcon />
                  <div className="text-slate-700 text-base font-semibold  leading-normal">
                    More
                  </div>
                </div>
                <ArrowUp />
              </button>
              <div className={`self-stretch h-[356px] pb-2 flex-col justify-start items-start gap-1 flex transition-all duration-300 ${
          isOpen ? 'opacity-100 max-h-[1000px]' : 'opacity-0 max-h-0'
        } overflow-hidden`}>
                <div className="self-stretch pl-[43px] pr-3 py-2 bg-white rounded-md justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                    <div className="w-2.5 h-2.5 relative">
                      <div className="w-2.5 h-2.5 left-[1px] top-[1px] absolute bg-red-300 rounded-full" />
                    </div>
                    <div className="text-slate-700 text-base font-semibold  leading-normal">
                      To respond
                    </div>
                  </div>
                </div>
                <div className="self-stretch pl-[43px] pr-3 py-2 bg-white rounded-md justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                    <div className="w-2.5 h-2.5 relative">
                      <div className="w-2.5 h-2.5 left-[1px] top-[1px] absolute bg-orange-300 rounded-full" />
                    </div>
                    <div className="text-slate-700 text-base font-semibold  leading-normal">
                      FYI
                    </div>
                  </div>
                </div>
                <div className="self-stretch pl-[43px] pr-3 py-2 bg-white rounded-md justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                    <div className="w-2.5 h-2.5 relative">
                      <div className="w-2 h-2 left-[1px] top-[1px] absolute bg-yellow-200 rounded-full" />
                    </div>
                    <div className="text-slate-700 text-base font-semibold  leading-normal">
                      Comment
                    </div>
                  </div>
                </div>
                <div className="self-stretch pl-[43px] pr-3 py-2 bg-white rounded-md justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                    <div className="w-2.5 h-2.5 relative">
                      <div className="w-2 h-2 left-[1px] top-[1px] absolute bg-green-300/opacity-95 rounded-full" />
                    </div>
                    <div className="text-slate-700 text-base font-semibold  leading-normal">
                      Notification
                    </div>
                  </div>
                </div>
                <div className="self-stretch pl-[43px] pr-3 py-2 bg-white rounded-md justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                    <div className="w-2.5 h-2.5 relative">
                      <div className="w-2 h-2 left-[1px] top-[1px] absolute bg-blue-200/opacity-95 rounded-full" />
                    </div>
                    <div className="text-slate-700 text-base font-semibold  leading-normal">
                      Meeting update
                    </div>
                  </div>
                </div>
                <div className="self-stretch pl-[43px] pr-3 py-2 bg-white rounded-md justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                    <div className="w-2.5 h-2.5 relative">
                      <div className="w-2 h-2 left-[1px] top-[1px] absolute bg-indigo-300 rounded-full" />
                    </div>
                    <div className="text-slate-700 text-base font-semibold  leading-normal">
                      Awaiting reply
                    </div>
                  </div>
                </div>
                <div className="self-stretch pl-[43px] pr-3 py-2 bg-white rounded-md justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                    <div className="w-2.5 h-2.5 relative">
                      <div className="w-2 h-2 left-[1px] top-[1px] absolute bg-purple-300/opacity-95 rounded-full" />
                    </div>
                    <div className="text-slate-700 text-base font-semibold  leading-normal">
                      Actioned
                    </div>
                  </div>
                </div>
                <div className="self-stretch pl-[43px] pr-3 py-2 bg-white rounded-md justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                    <div className="w-2.5 h-2.5 relative">
                      <div className="w-2 h-2 left-[1px] top-[1px] absolute bg-pink-300/opacity-95 rounded-full" />
                    </div>
                    <div className="text-slate-700 text-base font-semibold  leading-normal">
                      Marketing
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="border-t-2 border-gray-200 flex mt-4 pt-4">
            <div className="grow shrink basis-0 h-10 justify-start items-center gap-3 flex">
              <div className="w-10 h-10 rounded-[200px] justify-center items-center flex">
                <img
                  src={userData?.picture ? userData?.picture : profile}
                  alt=""
                  className="w-10 h-10 relative  rounded-[200px] border border-black"
                />
              </div>
              <div className="flex-col justify-start items-start inline-flex">
                <div className="text-slate-700 text-sm font-semibold   leading-tight">
                  {userData?.name?.length > 16
                    ? `${userData?.name?.slice(0, 16)}...`
                    : userData?.name}
                </div>
                <div className="text-slate-600 text-sm font-normal   leading-tight">
                  {userData?.email?.length > 16
                    ? `${userData?.email?.slice(0, 16)}...`
                    : userData?.email}
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                localStorage.clear();
                //   instance.logoutRedirect({
                //     postLogoutRedirectUri: "/",
                // });
                showNotification("success", "Logout Successfully");
                setTimeout(() => {
                  navigate("/");
                }, 1000);
              }}
              className="p-2 rounded-lg justify-center items-center gap-2 flex"
            >
              <LogoutIcon className=" relative" />
            </button>
          </div>
        </div>
      </div>
      <div className=" flex-1 w-[70%] max-h-[100vh] pt-3 flex-col justify-start items-start inline-flex">
        <div className="self-stretch  pt-8 bg-white rounded-tl-[40px] border-l border-t border-gray-300 flex-col justify-start items-start gap-8 flex">
          <div className="self-stretch flex-col justify-start items-start gap-6 flex">
            <div className=" flex flex-row w-full justify-between">
              <div className="flex flex-col pl-4">
                <div className="self-stretch text-gray-900 text-3xl font-semibold  leading-[38px]">
                  {headingText}
                </div>
                {/* <div className=" text-slate-600 text-sm font-normal  leading-tight">
                  2445 messages, 2 Unread
                </div> */}
              </div>
              <div className="pr-4 justify-start items-center gap-4 flex">
                <div onClick={()=>navigate('/data-removal-paymentplan')} className="self-stretch cursor-pointer px-[18px] py-2 bg-yellow-500 rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 inline-flex">
                  <div className="px-0.5 justify-center items-center flex">
                    <div className="text-white text-base font-semibold  leading-normal">
                      Data Removal
                    </div>
                  </div>
                </div>
                <div className="justify-start items-start gap-1 flex">
                  <button className="h-9 p-2 bg-white rounded-md justify-center items-center flex">
                    <SearchIcon />
                  </button>
                  <button className="h-9 p-2 bg-white rounded-md justify-center items-center flex">
                    <SettingIcon />
                  </button>
                  <button className="h-9 p-2 bg-white rounded-md justify-center items-center flex">
                    <NotificationIcon />
                  </button>
                </div>
                <div className="flex-col justify-start items-start inline-flex">
                  <div className="w-10 h-10 rounded-[200px] justify-center items-center inline-flex">
                    <img
                      src={userData?.picture ? userData?.picture : profile}
                      alt=""
                      className="w-10 h-10 relative  rounded-[200px] border border-black"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeaderSideBar;
