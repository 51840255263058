import React, { useState } from "react";
import { LoginSocialGoogle, LoginSocialMicrosoft } from "reactjs-social-login";
import ezpzLogo from "../../assets/logo.svg";
import { CrossIcon } from "../../assets/custom-icons";
import gmailIcon from "../../assets/google.svg";
import outlook from "../../assets/outlook.svg";
import axios from "axios";
import { showNotification } from "../../utils/error";
import ButtonLoader from "../loaders/ButtonLoader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, updateUserData } from "../../store/slices/UserSlice";

const base_url = process.env.REACT_APP_BASE_URL;
const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

const ConnectModal = ({ setIsOpen, isOpen, microsoft, google }) => {
  const { userInfo } = useSelector((state) => {
    return state.user;
  });
  const navigate = useNavigate();
  const [isResp, setIsResp] = useState(false);
  const [isRespMic, setIsRespMic] = useState(false);
  const dispatch = useDispatch();
  const handleLogin = async (data) => {
    try {
      const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : {};

      const body = {
        ...data,
        type: "google",
        email: userData.email,
        user_id: userData.id,
      };
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const response = await axios.post(`${base_url}/api/connect`, body, {
        headers: myHeaders,
      });
      if (response?.data) {
        const userData = { ...response?.data, ...response?.data?.data };

        response.data.data.selectedTab = "google";
        dispatch(loginUser(response.data.data));
        localStorage.setItem("userData", JSON.stringify(response?.data.data));
        // localStorage.setItem("userData", JSON.stringify(userData));
        // localStorage.setItem("jwt", response?.data?.token);
        showNotification("success", "Logged In Successfully");

        setTimeout(() => {
          setIsResp(false);
          if (response?.data?.data?.social_login[0]?.is_paid === 1) {
            navigate("/inbox");
            showNotification("success", "Logged In Successfully");
          } else {
            navigate("/signup-user-paymentplan");
            showNotification("success", "Logged In Successfully");
          }
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setIsResp(false);
      }, 1000);
      showNotification(
        "error",
        error?.response?.data?.message || error?.message
      );
    }
  };

  const authHandler = async (responseData) => {
    console.log(responseData);
    setIsRespMic(true);

    let userDat = { picture: "", email: "", name: "", type: "" };
    if (responseData && responseData.access_token) {
      userDat.email = responseData?.mail || "";
      userDat.name = responseData?.displayName || "";
      userDat.access_token = responseData?.access_token;

      const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : {};

      try {
        const body = {
          ...responseData,
          type: "microsoft",
          account_email: userData?.email,
        };

        const myHeaders = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        const response = await axios.post(`${base_url}/api/connect`, body, {
          headers: myHeaders,
        });
        if (response?.data) {
          userData.type = response?.data?.type;
          localStorage.setItem("userData", JSON.stringify(response?.data.data));
          // localStorage.setItem("jwt", response?.data?.token);
          response.data.data.selectedTab = "microsoft";
          dispatch(loginUser(response.data.data));
          setTimeout(() => {
            setIsRespMic(false);
            if (response?.data?.data?.social_login[0]?.is_paid === 1) {
              navigate("/inbox");
              showNotification("success", "Logged In Successfully");
            } else {
              navigate("/signup-user-paymentplan");
              showNotification("success", "Logged In Successfully");
            }
          }, 1000);
        }
      } catch (error) {
        setTimeout(() => {
          setIsRespMic(false);
        }, 1000);
        showNotification(
          "error",
          error?.response?.data?.message || error?.message
        );
      }
    }
  };
  const Com = () => {
    return (
      <button
        disabled={isRespMic}
        className="w-full px-4 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex"
      >
        {isRespMic ? (
          <ButtonLoader />
        ) : (
          <>
            <img src={outlook} alt="" className="w-6 h-6 relative" />
            <div className="text-slate-700 text-base font-semibold font-['Inter'] leading-normal">
              Connect with Microsoft Outlook
            </div>
          </>
        )}
      </button>
    );
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-[498px] overflow-auto min-h-[252px] bg-gray-100 rounded-xl shadow flex-col justify-start items-center inline-flex">
            <div className="w-[90%] pt-4 flex items-center justify-between">
              <div></div>
              <img src={ezpzLogo} alt="" className="   rounded-full" />
              <CrossIcon
                className="cursor-pointer"
                onClick={() => {
                  dispatch(
                    updateUserData({
                      key: "selectedTab",
                      value: userInfo && userInfo?.social_login[0]?.type,
                    })
                  );
                  setIsOpen(false);
                }}
              />
            </div>
            <div className="py-4 text-center text-gray-900 text-lg font-semibold font-['Inter'] leading-7">
              Connect Your Email Account
            </div>
            <div className="w-full  px-6 pb-6 flex-col justify-start items-start gap-5 flex">
              {microsoft && (
                <LoginSocialMicrosoft
                  client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ""}
                  redirect_uri={"https://dylnn.aidevlab.com"}
                  onResolve={({ provider, data }) => {
                    console.log(data);
                    authHandler(data);
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                  access_type="offline"
                  prompt="select_account"
                  response_type="code"
                  scope="Calendars.Read Mail.Read Mail.ReadWrite email profile openid User.Read Files.ReadWrite.AppFolder Files.ReadWrite.All Files.Read.All Files.SelectedOperations.Selected MailboxSettings.Read MailboxSettings.ReadWrite AccessReview.Read.All AccessReview.ReadWrite.All AccessReview.ReadWrite.Membership"
                  className="w-full"
                >
                  <Com isRespMic={isRespMic} />
                </LoginSocialMicrosoft>
              )}

              <div className="w-full  justify-center items-center gap-3 flex">
                {google && (
                  <LoginSocialGoogle
                    client_id={client_id}
                    onResolve={({ provider, data }) => {
                      setIsResp(true);
                      handleLogin(data);
                    }}
                    onReject={(err) => {
                      setTimeout(() => {
                        setIsResp(false);
                      }, 1000);
                    }}
                    scope="openid email profile https://mail.google.com/ https://www.googleapis.com/auth/gmail.readonly"
                    // typeResponse="accessToken"
                    access_type="offline"
                    // prompt='force'
                    className="w-full"
                  >
                    <button
                      disabled={isResp}
                      className="w-full px-4 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex"
                    >
                      {isResp ? (
                        <ButtonLoader />
                      ) : (
                        <>
                          <img
                            src={gmailIcon}
                            alt=""
                            className="w-6 h-6 relative"
                          />
                          <div className="text-slate-700 text-base font-semibold font-['Inter'] leading-normal">
                            Connect with Gmail
                          </div>
                        </>
                      )}
                    </button>
                  </LoginSocialGoogle>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConnectModal;
