import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { showNotification } from "../../utils/error";
import Loader from "../../components/loaders/Loader";
import { Link } from "react-router-dom";
import profileLogo from "../../assets/profile_mask2.png";
import rightChev from "../../assets/rightChev.svg";

import MainHeaderSideBar from "../../components/mainLayout/MainHeaderSideBar";
import { dateAndTime } from "../../utils/constants";
import { useSelector } from "react-redux";
import ConnectModal from "../../components/modals/ConnectModal";
const PaidBlockMails = () => {
  const { userInfo } = useSelector((state) => {
    return state.user;
  });
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const [isOpen, setIsOpen] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const totalCount = allMessages.length;
  const moreRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (moreRef.current && !moreRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const [itemsToShow, setItemsToShow] = useState("20");

  const getAllMessages = async () => {
    setIsLoading(true);
    try {
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const userData = JSON.parse(localStorage.getItem("userData"));
      const body = {
        token: userData?.access_token,
        type: userInfo?.selectedTab,
      };
      const response = await axiosInstance.post(
        "/api/fetch-paid-emails",
        body,
        {
          headers: myHeaders,
        }
      );
      if (response?.data?.emails?.length > 0) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setAllMessages(response?.data?.emails);
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      showNotification("error", error?.response?.data?.error || error?.message);
    }
  };

  const handleSelectChange = (event) => {
    setItemsToShow(event.target.value);
  };

  const getFilteredMessages = () => {
    if (itemsToShow === "all") {
      return allMessages;
    }
    const numberOfItems = parseInt(itemsToShow, 10);
    return allMessages?.slice(0, numberOfItems);
  };
  useEffect(() => {
    if (userInfo?.selectedTab) {
      if (
        !Array.isArray(userInfo.social_login) ||
        !userInfo.social_login.some((obj) => obj.type === userInfo.selectedTab)
      ) {
        setIsOpen(true);
        return;
      }
    }
    getAllMessages();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userInfo]);
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value === "See All" ? totalCount : parseInt(value, 10));
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  const paginate = (value) => {
    setCurrentPage(value);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(totalCount / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const getPaginatedMessages = () => {
    const start = indexOfFirstItem;
    const end = indexOfLastItem;
    return allMessages.slice(start, end);
  };
  return (
    <MainHeaderSideBar headingText={"Paid block mails"}>
      <ConnectModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        microsoft={userInfo.selectedTab == "microsoft"}
        google={userInfo.selectedTab == "google"}
      />
      <div className="flex justify-end items-center mt-2 ml-[auto] pr-8">
        <div className="opacity-60 text-black text-sm font-semibold">
          Showing {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, totalCount)}{" "}
          of {totalCount} mails
        </div>
        <div className="flex  justify-center items-center">
          <div className="w-[86px] h-[30px] ml-2 relative">
            <div className="w-[86px] h-[30px] p-2 justify-center items-center flex rounded-[27px] border border-[#787878]" />
            <button disabled={currentPage === 1}>
              <img
                src={rightChev}
                alt="left"
                className="w-auto h-[15px] rotate-180 left-[10px] top-[7px] absolute cursor-pointer"
                onClick={prevPage}
              />
            </button>
            <button
              disabled={
                isLoading ||
                currentPage === Math.ceil(totalCount / itemsPerPage)
              }
            >
              <img
                src={rightChev}
                alt="right"
                className="rotate-270 opacity-90 w-auto h-[15px] left-[55px] top-[7px] absolute cursor-pointer"
                onClick={nextPage}
              />
            </button>
          </div>
        </div>
      </div>

      <div className="w-full overflow-auto  h-[80vh] px-8 pt-6 pb-16 flex flex-col">
        {isLoading ? (
          <div className="flex justify-center items-center h-[40vh]">
            <Loader />
          </div>
        ) : (
          <>
            {allMessages?.length > 0 ? (
              <>
                {getPaginatedMessages()?.map((message) => (
                  <Link
                    to={`/paid-mails/${message?.mail_id}`}
                    key={message?.mail_id}
                    state={{ allMessages: allMessages, from: "paid" }}
                  >
                    <div className="w-full mt-6  p-6 bg-gray-50 rounded-lg border border-zinc-500/opacity-20  justify-between gap-4 flex flex-row">
                      <div className="flex w-[80%] gap-4">
                        <img
                          alt=""
                          className="w-[49px] h-[49px] rounded-full"
                          src={profileLogo}
                        />
                        <div className="flex flex-col gap-1 w-full overflow-hidden">
                          <div className="text-black text-lg font-medium font-['Inter']">
                            {message?.from_name}
                          </div>
                          <div className="text-gray-800 text-sm font-normal truncate">
                            {message?.subject}
                          </div>
                          <div className="text-slate-700 text-xs font-normal truncate">
                            {message?.snippet?.replace(/\r\n|\n/g, " ")}
                          </div>
                        </div>
                      </div>
                      <div
                        className="flex flex-col gap-2 w-[20%] items-end"
                        style={{ alignItems: "end" }}
                      >
                        <span className="text-right text-[11px]">
                          {message?.datetime
                            ? dateAndTime(message?.datetime)
                            : ""}
                        </span>
                        <span className="pl-1.5 pr-2 py-0.5 flex justify-center items-center bg-red-50 rounded-2xl border border-rose-200">
                          <div className="w-2 h-2 relative">
                            <div className="w-1.5 h-1.5 absolute bg-red-300 rounded-full" />
                          </div>
                          <div className="text-red-300 text-xs font-medium">
                            To Respond
                          </div>
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </>
            ) : (
              <div className="h-[20vh] flex justify-center items-center">
                <span className="text-red-600">No Data Found!</span>
              </div>
            )}
          </>
        )}
      </div>
    </MainHeaderSideBar>
  );
};

export default PaidBlockMails;
