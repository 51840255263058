import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import background from "../../../assets/background.svg";
import BackIcon from "../../../components/BackIcon";
import { showNotification } from "../../../utils/error";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../store/slices/UserSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const base_url = process.env.REACT_APP_BASE_URL;

const SignupPhoneNo = () => {
  const token = localStorage.getItem("jwt");
  const userInfo = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    phone_number: Yup.string().required("Phone number is required"),
  });

  const initialValues = {
    phone_number: "",
    country_code: "",
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = {
        id: userInfo?.id,
        phone_number: values?.phone_number || null,
        country_code: values?.country_code || null,
      };
      const response = await axios.post(
        base_url + "/api/set-phone-number",
        body,
        {
          headers: myHeaders,
        }
      );
      if (response?.data?.status) {
        setTimeout(() => {
          setSubmitting(false);
          if (userInfo?.social_login[0]?.is_paid === 1) {
            navigate("/inbox");
            showNotification("success", response?.data?.message);
          } else {
            navigate("/signup-user-paymentplan");
            showNotification("success", response?.data?.message);
          }
        }, 2000);
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
        showNotification("error", response?.data?.message);
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitting(false);
      }, 2000);
      showNotification(
        "error",
        error?.response?.data?.message || error?.message
      );
    }
  };

  return (
    <div className="w-full h-[100vh] px-8 bg-white flex-col justify-center items-center flex">
      <div
        className="w-[44%] h-[100vh] px-8 flex-col justify-center items-center gap-8 flex"
        style={{ background: `url(${background})` }}
      >
        <div className="w-[70%] flex-col justify-center items-center gap-8 flex">
          <div className="flex-col justify-center items-center gap-6 flex">
            <div className="w-12 h-12 relative">
              <img className="h-[48.34px] absolute" src={logo} alt="ok" />
            </div>
            <div className="flex-col gap-3 flex">
              <div className="text-center text-gray-900 text-3xl font-semibold leading-[38px]">
                Enter the phone number
              </div>
              <div className="text-center text-slate-600 text-base font-normal leading-normal">
                Hey! Welcome back
              </div>
            </div>
          </div>
          <div className="w-full">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="w-full">
                    <div className="pb-4">
                      <div className="text-slate-700 text-sm font-medium leading-tight py-2">
                        Phone Number *
                      </div>
                      <div className="w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 items-center flex">
                        <PhoneInput
                          country={"us"}
                          value={initialValues.phone_number}
                          onChange={(phone, country) => {
                            setFieldValue(
                              "phone_number",
                              phone.slice(country.dialCode.length)
                            );
                            setFieldValue("country_code", country.dialCode);
                          }}
                          inputStyle={{
                            width: "100%",
                            border: "none",
                            outline: "none",
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="phone_number"
                        component="div"
                        className="text-red-600 text-[14px]"
                      />
                    </div>
                    <button
                      disabled={isSubmitting}
                      className={`w-full py-2.5 mt-3 bg-[#E7C801] rounded-lg shadow border border-yellow-500 justify-center items-center gap-1.5 inline-flex`}
                    >
                      <div className="justify-center items-center flex">
                        <div className="text-white text-base font-semibold leading-normal">
                          {isSubmitting ? (
                            <div className="">
                              <ButtonLoader />
                            </div>
                          ) : (
                            "Continue"
                          )}
                        </div>
                      </div>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPhoneNo;
