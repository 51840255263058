import { Navigate } from "react-router-dom";

function AuthRoute({ children }) {
  const pathName = window.location.pathname
  const jwt = localStorage?.getItem("jwt");
  if (!jwt) {
    return children;
  }
  return <Navigate to={"/inbox"} />;
}

export default AuthRoute;
